import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ReloadOutlined } from '@ant-design/icons';

import { PageHeader, Button } from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';

import HomeSiteCard from '../Home/HomeSiteCard';

import '../../less/StationDashboardScreen.less';

export default function FarmSiteDashboardScreen(props) {
  const history = useHistory();
  const { siteId } = useParams();

  const [site, setSite] = useState(null);
  const [cardKey, setCardKey] = useState(Math.random().toString());

  useEffect(() => {
    (async () => {
      const siteRes = await ToolRequest.request('GET', `/v1/farm-site/${siteId}`);
      setSite(siteRes);
    })();
  }, []);

  return (
    <PageHeader
      title={`Site Dashboard (${site ? site.name : '...'})`}
      onBack={() => history.push('/farm-site')}
    >
      {site && (
        <HomeSiteCard
          key={cardKey}
          title={(
            <>
              <Button
                type="link"
                size="small"
                icon={<ReloadOutlined />}
                style={{float: 'right'}}
                onClick={() => setCardKey(Math.random().toString())}
              />
              <span>Latest Data</span>
            </>
          )}
          site={site}
        />
      )}
    </PageHeader>
  );
};
