import React, { useState, useRef, useEffect } from 'react';

import { Row, Col, Card, Input } from 'antd';

import SuperTableSearchColumn from './SuperTableSearchColumn';

const { Search } = Input;

let searchInputTimeout = null;
let skippedFirstUpdate = false;

export default function SuperTableSearch(props) {
  const skippedFirstUpdate = useRef(false);

  const {
    showSearchInput = false,
    onChange = () => {},
    columns = [],
    defaultValues = {},
  } = props;

  const [form, setForm] = useState(defaultValues);

  useEffect(() => {
    if (!skippedFirstUpdate.current) {
      return;
    }

    if (searchInputTimeout) {
      clearTimeout(searchInputTimeout);
    }

    searchInputTimeout = setTimeout(() => {
      onChange(form);
    }, 300);
  }, [form]);

  useEffect (() => {
    skippedFirstUpdate.current = true;
  }, []);

  const renderSearchColumns = () => {
    const colCount = 4;

    let rows = [];
    columns.forEach((column, index) => {
      const component = SuperTableSearchColumn({
        ...column,
        onChange: (value) => {
          setForm({
            ...form,
            [column.key]: value,
          });
        },
        value: form[column.key],
      });

      if (index % colCount) {
        rows[rows.length - 1].push(component);
      } else {
        rows.push([component]);
      }
    });

    return rows.map((row, rowIndex) => {
      return (
        <Row key={rowIndex} gutter={8}>
          {row.map((component, colIndex) => (
            <Col key={colIndex} sm={{ span: 24 / colCount }} xs={{ span: 24 }}>
              {component}
            </Col>
          ))}
        </Row>
      );
    });
  };

  return (
    <Card className="search-form-container">
      {showSearchInput && (
        <div>
          <Search
            placeholder="Search..."
            autoComplete="off"
            value={form.search}
            onChange={(e) => {
              setForm({
                ...form,
                search: e.target.value,
              });
            }}
          />
        </div>
      )}
      <div>
        {renderSearchColumns()}
      </div>
    </Card>
  );
};
