import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import { useDispatch, useSelector } from "react-redux";

import { Spin } from 'antd';

import LoginScreen from '../Login/LoginScreen';
import AppScreen from '../App/AppScreen';

import * as Action from '../../actions';
import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolUser from '../../tools/ToolUser';

import '../../less/SplashScreen.less';

import enUS from 'antd/es/locale/en_US';
import zhTW from 'antd/es/locale/zh_TW';
import zhCN from 'antd/es/locale/zh_CN';

export default function SplashScreen(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const userData = useSelector(state => state.user);

  const [loaded, setLoaded] = useState(false);

  let locale = enUS;
  if (userData) {
    switch (userData.locale) {
      case 'zh-tw':
        locale = zhTW;
        break;
      case 'zh-cn':
        locale = zhCN;
        break;
    }
  }

  useEffect(() => {
    Promise.all([
      ToolRequest.request('GET', '/v1/metadata')
        .then(metadata => {
          dispatch(Action.updateMetadata(metadata));
        }),

      ToolRequest.request('GET', '/v1/station-zone-io-config')
        .then(zoneIoConfigs => {
          dispatch(Action.updateZoneIoConfigs(zoneIoConfigs.data));
        }),

      ToolRequest.request('GET', '/v1/station-setting-config')
        .then(stationSettingConfigs => {
          dispatch(Action.updateStationSettingConfigs(stationSettingConfigs.data));
        }),

      ToolRequest.request('GET', '/v1/station-device-config')
        .then(stationDeviceConfigs => {
          dispatch(Action.updateStationDeviceConfigs(stationDeviceConfigs.data));
        }),

      ToolRequest.request('GET', '/v1/station-zone-automation-config')
        .then(zoneAutomationConfigs => {
          dispatch(Action.updateZoneAutomationConfigs(zoneAutomationConfigs.data));
        }),

      ToolRequest.request('GET', '/v1/user/me')
        .then(async (user) => {
          // ToolUser.updateLocale(user.locale);
          ToolUser.updateLocale('en');

          dispatch(Action.updateUser(user));

          await ToolRequest.request('GET', '/v1/user-permission')
            .then(userPermissions => {
              dispatch(Action.updateUserPermissions(userPermissions));

              if (!Object.keys(userPermissions).length) {
                throw new Error();
              }
            });

          await ToolRequest.request('GET', `/v1/company/${user.companyId}/setting`)
            .then(companySettings => {
              dispatch(Action.updateCompanySettings(companySettings));
            });
        })
        .catch(err => {
          history.replace(`/login?next=${encodeURIComponent(history.location.pathname)}`);
        }),
    ])
    .then(() => {
      setLoaded(true);
    });
  }, []);

  return (
    <ConfigProvider locale={locale}>
      {loaded ? (
        <Switch>
          <Route exact path="/login" component={LoginScreen} />
          <Route path="/" component={AppScreen} />
        </Switch>
      ) : (
        <div id="splashContainer">
          <Spin />
        </div>
      )}
    </ConfigProvider>
  );
};
