import React, { useState, useEffect }  from 'react';
import { Card, Row, Col, Statistic, List, Tooltip, Badge, Button, Space, Divider } from 'antd';
import { HomeOutlined, FunnelPlotFilled, FireFilled, InboxOutlined, ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolMath from '../../tools/ToolMath';

import HomeSiteCardStationListItem from './HomeSiteCardStationListItem';
import HomeSiteCardSeedlingDetailModal from './HomeSiteCardSeedlingDetailModal';
import HomeSiteCardPlantingDetailModal from './HomeSiteCardPlantingDetailModal';
import SpinFull from '../../components/SpinFull';
import DayCountDisplay from '../../components/DayCountDisplay';

export default function HomeSiteCard(props) {
  const { site, title } = props;

  const [loaded, setLoaded] = useState(false);
  const [cardKey, setCardKey] = useState(Math.random().toString());
  const [stats, setStats] = useState(null);
  const [seedlingTraySummary, setSeedlingTraySummary] = useState(null);
  const [plantingTraySummary, setPlantingTraySummary] = useState(null);
  const [stations, setStations] = useState(null);
  const [seedPurchases, setSeedPurchases] = useState({});

  const [seedlingDetailModal, setSeedlingDetailModal] = useState(null);
  const [plantingDetailModal, setPlantingDetailModal] = useState(null);

  useEffect(() => {
    setLoaded(false);

    (async () => {
      const stats = await ToolRequest.request('GET', `/v1/farm-site/${site.id}/stats`);
      setStats(stats);

      let seedPurchaseIds = [];

      const seedlingTraySummaryRes = await ToolRequest.request('GET', `/v1/farm-site/${site.id}/seedling-tray-summary`);
      setSeedlingTraySummary(seedlingTraySummaryRes.data);
      for (let summary of seedlingTraySummaryRes.data) {
        seedPurchaseIds = [...seedPurchaseIds, ...summary.seedPurchases.map(seedPurchase => seedPurchase.id)];
      }

      const plantingTraySummaryRes = await ToolRequest.request('GET', `/v1/farm-site/${site.id}/planting-tray-summary`);
      setPlantingTraySummary(plantingTraySummaryRes.data);
      for (let summary of plantingTraySummaryRes.data) {
        seedPurchaseIds = [...seedPurchaseIds, ...summary.seedPurchases.map(seedPurchase => seedPurchase.id)];
      }

      // filter unique seedPurchaseIds
      seedPurchaseIds = seedPurchaseIds.filter((value, index, self) => self.indexOf(value) === index);

      for (let seedPurchaseId of seedPurchaseIds) {
        const seedPurchaseRes = await ToolRequest.request('GET', `/v1/farm-seed-purchase/${seedPurchaseId}`);

        setSeedPurchases(setSeedPurchases => ({
          ...setSeedPurchases,
          [seedPurchaseId]: seedPurchaseRes,
        }));
      }

      const siteStationsRes = await ToolRequest.request('GET', `/v1/farm-site/${site.id}/station`);

      const stations = [];
      for (let siteStation of siteStationsRes.data) {
        const station = await ToolRequest.request('GET', `/v1/station/${siteStation.stationId}`);
        stations.push(station);
      }
      stations.sort((a, b) => {
        if (a.displayName < b.displayName) {
          return -1;
        } else if (a.displayName > b.displayName) {
          return 1;
        }
        return 0;
      });
      setStations(stations);

      setLoaded(true);
    })();
  }, [cardKey]);

  return (
    <Card
      className="card-container"
      title={title || (
        <>
          <Button
            type="link"
            size="small"
            icon={<ReloadOutlined />}
            style={{float: 'right'}}
            onClick={() => setCardKey(Math.random().toString())}
          />
          <a href={`#/farm-site/dashboard/${site.id}`}>
            <HomeOutlined />
            <span style={{marginLeft: 16}}>{site.name}</span>
          </a>
        </>
      )}
    >
      {loaded ? (
        <>
          {stations.length > 0 && (
            <List
              bordered
              size="small"
              dataSource={stations}
              renderItem={item => <HomeSiteCardStationListItem station={item} />}
              style={{ marginBottom: 16 }}
              className="small-list-container"
              header={(
                <>
                  <span style={{marginRight: 8}}>Active Stations</span>
                  <Badge count={stations.length} />
                </>
              )}
            />
          )}

          <Row>
            <Col span={8}>
              <Statistic title="Available Seed Purchases" value={stats.seedPurchaseAvailableCount} />
            </Col>
            <Col span={8}>
              <Statistic title="Active Seedling Trays" value={`${stats.seedlingTrayActiveCount} / ${stats.seedlingTrayCount}`} />
            </Col>
            <Col span={8}>
              <Statistic title="Active Planting Trays" value={`${stats.plantingTrayActiveCount} / ${stats.plantingTrayCount}`} />
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <Statistic title="Last Seedling" value={stats.latestSeedlingRecordCreatedAt ?
                `${ToolMath.daysFromNow(stats.latestSeedlingRecordCreatedAt)} (${moment(stats.latestSeedlingRecordCreatedAt).format('D/M')})`
                : '-'} />
            </Col>
            <Col span={8}>
              <Statistic title="Last Planting" value={stats.latestPlantingRecordCreatedAt ?
                `${ToolMath.daysFromNow(stats.latestPlantingRecordCreatedAt)} (${moment(stats.latestPlantingRecordCreatedAt).format('D/M')})`
                : '-'} />
            </Col>
            <Col span={8}>
              <Statistic title="Last Harvesting" value={stats.latestHarvestRecordCreatedAt ?
                `${ToolMath.daysFromNow(stats.latestHarvestRecordCreatedAt)} (${moment(stats.latestHarvestRecordCreatedAt).format('D/M')})`
                : '-'} />
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col xs={24} lg={12}>
              <List
                className="small-list-container"
                header={(
                  <>
                    <span style={{marginRight: 8}}>Seedling Summary</span>
                    <Badge count={seedlingTraySummary.length} />
                  </>
                )}
                bordered
                size="small"
                dataSource={seedlingTraySummary}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={`${item.date} (${ToolMath.daysFromNow(item.date)})`}
                      description={(
                        <List
                          size="small"
                          dataSource={item.seedPurchases}
                          renderItem={seedPurchase => (
                            <List.Item
                              key={seedPurchase.id}
                              className="clickable"
                              onClick={() => {
                                setSeedlingDetailModal({
                                  key: Math.random().toString(),
                                  visible: true,
                                  seedlingTrays: seedPurchase.seedlingTrays,
                                });
                              }}
                            >
                              <List.Item.Meta
                                title={
                                  <>
                                    <div style={{marginBottom: 4}}>{seedPurchases[seedPurchase.id].name}</div>
                                    <DayCountDisplay value={ToolMath.daysFromNowNumber(item.date)} target={seedPurchases[seedPurchase.id].seedlingDayCount} />
                                  </>
                                }
                                description={
                                  <div style={{textAlign: 'right'}}>
                                    <Tooltip title="Total No. of Seedlings"><FunnelPlotFilled /> {seedPurchase.seedlingTrays.map(item => item.amount).reduce((prev, next) => prev + next)}</Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="No. of Trays"><InboxOutlined /> {seedPurchase.seedlingTrays.length}</Tooltip>
                                  </div>
                                }
                              />
                            </List.Item>
                          )}
                        />
                      )}
                    />
                  </List.Item>
                )}
              />
              {seedlingDetailModal && (
                <HomeSiteCardSeedlingDetailModal
                  key={seedlingDetailModal.key}
                  seedlingTrays={seedlingDetailModal.seedlingTrays}
                  visible={seedlingDetailModal.visible}
                  onCancel={() => {
                    setSeedlingDetailModal({
                      ...seedlingDetailModal,
                      visible: false,
                    });
                  }}
                />
              )}
            </Col>
            <Col xs={24} lg={12}>
              <List
                className="small-list-container"
                header={(
                  <>
                    <span style={{marginRight: 8}}>Planting Summary</span>
                    <Badge count={plantingTraySummary.length} />
                  </>
                )}
                bordered
                size="small"
                dataSource={plantingTraySummary}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={`${item.date} (${ToolMath.daysFromNow(item.date)})`}
                      description={(
                        <List
                          size="small"
                          dataSource={item.seedPurchases}
                          renderItem={seedPurchase => (
                            <List.Item
                              key={seedPurchase.id}
                              className="clickable"
                              onClick={() => {
                                setPlantingDetailModal({
                                  key: Math.random().toString(),
                                  visible: true,
                                  plantingTrays: seedPurchase.plantingTrays,
                                });
                              }}
                            >
                              <List.Item.Meta
                                title={
                                  <>
                                    <div style={{marginBottom: 4}}>{seedPurchases[seedPurchase.id].name}</div>
                                    <DayCountDisplay value={ToolMath.daysFromNowNumber(item.date)} target={seedPurchases[seedPurchase.id].plantingDayCount} />
                                  </>
                                }
                                description={
                                  <div style={{textAlign: 'right'}}>
                                    <Tooltip title="Total No. of Crops"><FireFilled /> {seedPurchase.plantingTrays.map(item => item.amount).reduce((prev, next) => prev + next)}</Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="No. of Trays"><InboxOutlined /> {seedPurchase.plantingTrays.length}</Tooltip>
                                  </div>
                                }
                              />
                            </List.Item>
                          )}
                        />
                      )}
                    />
                  </List.Item>
                )}
              />
              {plantingDetailModal && (
                <HomeSiteCardPlantingDetailModal
                  key={plantingDetailModal.key}
                  plantingTrays={plantingDetailModal.plantingTrays}
                  visible={plantingDetailModal.visible}
                  onCancel={() => {
                    setPlantingDetailModal({
                      ...plantingDetailModal,
                      visible: false,
                    });
                  }}
                />
              )}
            </Col>
          </Row>
        </>
      ) : <SpinFull />}
    </Card>
  );
};
