import React from 'react';

import { Switch, Select, Cascader, DatePicker } from 'antd';

const { Option } = Select;

export default function SuperTableSearchColumn(props) {
  const {
    template,
    key,
    title = '',
    render,
    value,
    onChange = () => {},
  } = props;

  switch (template) {
    case 'switch': {
      return (
        <div className="search-form-switch-container">
          <Switch
            checked={value}
            onChange={onChange}
          />
          <span>{title}</span>
        </div>
      );
    }

    case 'select': {
      const { options, allowClear = true } = props;

      return (
        <Select
          className="select"
          placeholder={title}
          onChange={onChange}
          value={value}
          allowClear={allowClear}
        >
          {options.map(option => (
            <Option value={option.value}>{option.label}</Option>
          ))}
        </Select>
      );
    }

    case 'cascader': {
      const { options } = props;

      return (
        <Cascader
          className="select"
          placeholder={title}
          onChange={onChange}
          value={value}
          options={options}
        />
      );
    }

    case 'datePicker': {
      const { options } = props;

      return (
        <DatePicker
          className="select"
          placeholder={title}
          onChange={onChange}
          value={value}
        />
      );
    }

    default:
      return title;
  }

  return null;
};