import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, message, DatePicker, InputNumber } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    sm: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;
const { TextArea } = Input;

export default function FarmSeedPurchaseAddScreen(props) {
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [siteOptions, setSiteOptions] = useState([]);

  const { metadata } = props;

  useEffect(() => {
    (async () => {
      const sitesRes = await ToolRequest.request('GET', '/v1/farm-site', {
        count: 1000,
      });

      setSiteOptions(sitesRes.data.map(site => ({
        value: site.id,
        label: site.name,
      })));

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const res = await ToolRequest.request('POST', '/v1/farm-seed-purchase/batch', {
        siteId: values.siteId[0],
        name: values.name,
        seedlingDayCount: values.seedlingDayCount,
        plantingDayCount: values.plantingDayCount,
        remarks: values.remarks,
        purchasedAt: moment(values.purchasedAt).startOf('day').toISOString(),
        amount: values.amount,
      });

      message.success('Create Seed Purchase successfully');

      history.push('/farm-seed-purchase');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error('Unknown errors. Please retry');
        }
      }

      message.error('Unknown error. Please retry');
    }
  };

  return loaded ? (
    <PageHeader
      title="Add a Seed Purchase"
      onBack={() => history.push('/farm-seed-purchase')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={{
            amount: 1,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Site"
            name="siteId"
            rules={[
              {
                required: true,
                message: 'Please select a site',
              },
            ]}
          >
            <Cascader
              options={siteOptions}
              placeholder="Select Site"
            />
          </Form.Item>

          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name',
              },
            ]}
          >
            <Input
              placeholder="Name"
            />
          </Form.Item>

          <Form.Item
            label="Days of Seedling"
            name="seedlingDayCount"
            rules={[
              {
                required: true,
                message: 'Please enter the days of seedling',
              },
            ]}
          >
            <InputNumber
              placeholder="Days"
              min={1}
            />
          </Form.Item>

          <Form.Item
            label="Days of Planting"
            name="plantingDayCount"
            rules={[
              {
                required: true,
                message: 'Please enter the days of planting',
              },
            ]}
          >
            <InputNumber
              placeholder="Days"
              min={1}
            />
          </Form.Item>

          <Form.Item
            label="Purchase Date"
            name="purchasedAt"
            rules={[
              {
                required: true,
                message: 'Please select the purchase date',
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            label="Quantity"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please enter the quantity',
              },
            ]}
          >
            <InputNumber
              placeholder="1"
              min={1}
            />
          </Form.Item>

          <Form.Item
            label="Remarks"
            name="remarks"
          >
            <Input.TextArea
              placeholder="Remarks"
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
