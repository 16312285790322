import React, { useState } from 'react';

import SuperTableSearch from './SuperTableSearch';
import SuperTableContent from './SuperTableContent';

export default function SuperTable(props) {
  const [pagination, setPagination] = useState({
    pageSize: 10,
    showQuickJumper: true,
    showSizeChanger: true,
    showTotal: (total, range) => `${range[0]} - ${range[1]} (Total ${total} records)`,
    ...(props.pagination || {}),
  });
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const {
    id,
    className,
    tableKey,
    onTableKeyChange = () => {},
    selectable = false,
    columns = [],
    loadDataSource = () => {},
    onSelectedRowChange = () => {},
    showSearchInput = false,
    searchQueryParams = (values) => values,
    searchColumns = [],
    expandable,
  } = props;

  const defaultQueryParams = {};
  searchColumns.forEach(column => {
    if (column.defaultValue !== undefined) {
      defaultQueryParams[column.key] = column.defaultValue;
    }
  });

  const [queryParams, setQueryParams] = useState(defaultQueryParams);

  // TODO bulk actions

  const onSearchChange = (values) => {
    if (props.pagination !== false) {
      setPagination({
        ...pagination,
        current: 1,
      });
    }
    setQueryParams(searchQueryParams(values));
    onTableKeyChange(Math.random().toString());
  };

  return (
    <>
      {!!(showSearchInput || searchColumns.length) && (
        <SuperTableSearch
          showSearchInput={showSearchInput}
          onChange={onSearchChange}
          columns={searchColumns}
          defaultValues={defaultQueryParams}
        />
      )}
      <SuperTableContent
        id={id}
        className={className}
        key={tableKey}
        columns={columns}
        queryParams={queryParams}
        loadDataSource={loadDataSource}
        dataSource={dataSource}
        onChangeDataSource={setDataSource}
        selectable={selectable}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowChange={selectedRowKeys => {
          setSelectedRowKeys(selectedRowKeys);
          onSelectedRowChange(selectedRowKeys);
        }}
        onQueryParamsChange={queryParams => setQueryParams(queryParams)}
        onPaginationChange={pagination => {
          setPagination(pagination);
          setSelectedRowKeys([]);
        }}
        pagination={props.pagination !== false ? pagination : false}
        expandable={expandable}
      />
    </>
  );
};
