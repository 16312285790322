import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Modal, List, Avatar } from 'antd';
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import * as ToolRequest from '../../tools/ToolRequest';

export default function StationSettingChangeModal(props) {
  const {
    station,
    stationSettings,
    stationDevices,
    zones,
    changes,
    visible,
    onCancel,
  } = props;
  const stationDeviceConfigsData = useSelector(state => state.stationDeviceConfigs);

  return (
    <Modal
      title={`Unsaved changes: ${changes.length}`}
      visible={visible}
      onCancel={onCancel}
      width="80%"
      footer={null}
    >
      <List
        style={{ marginTop: -24, marginBottom: -24 }}
        dataSource={changes}
        renderItem={(item, i) => {
          const {
            name,
            action,
            id,
            key,
            value,
            data,
            extra,
          } = item;

          let message = JSON.stringify(item);

          switch (name) {
            case 'STATION_SETTING': {
              message = `Set station setting "${key}" to "${value}"`;
              break;
            }
            case 'DEVICE': {
              switch (action) {
                case 'CREATE': {
                  message = `Add a ${data.model} device "${data.name}"`;
                  break;
                }
                case 'UPDATE': {
                  const device = stationDevices.find(device => device.id === id);
                  message = `Set device "${device.name}" settings`;
                  break;
                }
                case 'REMOVE': {
                  message = `Remove a device "${extra.name}"`;
                  break;
                }
              }
              break;
            }
            case 'DEVICE_SETTING': {
              const deviceName = stationDevices.find(stationDevice => stationDevice.id === id).name;
              message = `Set device "${deviceName}" setting "${key}" to "${value}"`;
              break;
            }
            case 'ZONE': {
              const name = zones.find(zone => zone.id === id).name;
              switch (action) {
                case 'CREATE': {
                  message = `Add a zone "${data.name}"`;
                  break;
                }
                case 'UPDATE': {
                  message = `Set zone "${name}" settings`;
                  break;
                }
                case 'REMOVE': {
                  message = `Remove a zone "${extra.name}"`;
                  break;
                }
              }
              break;
            }
            case 'ZONE_IO': {
              const ioName = zones.find(zone => zone.ios.some(io => io.id === id)).ios.find(io => io.id === id).name;
              switch (action) {
                case 'CREATE': {
                  message = `Add a zone IO "${data.name}"`;
                  break;
                }
                case 'UPDATE': {
                  message = `Set zone IO "${name}" settings`;
                  break;
                }
                case 'REMOVE': {
                  message = `Remove a zone IO "${extra.name}"`;
                  break;
                }
              }
              break;
            }
            case 'ZONE_IO_SETTING': {
              const ioName = zones.find(zone => zone.ios.some(io => io.id === id)).ios.find(io => io.id === id).name;
              message = `Set zone IO "${ioName}" setting "${key}" to "${value}"`;
              break;
            }
            case 'ZONE_IO_DEVICE': {
              const ioName = zones.find(zone => zone.ios.some(io => io.id === data.zoneIoId)).ios.find(io => io.id === data.zoneIoId).name;
              switch (action) {
                case 'CREATE': {
                  message = `Add a zone IO device for IO "${ioName}"`;
                  break;
                }
                // case 'UPDATE': {
                //   message = `Set zone IO "${ioName}" device setting "${key}" to "${value}"`;
                //   break;
                // }
                case 'REMOVE': {
                  message = `Remove a zone IO device "${ioName}"`;
                  break;
                }
              }
              break;
            }
            case 'ZONE_IO_DEVICE_SETTING': {
              const ioName = zones.find(zone => zone.ios.some(io => io.id === data.zoneIoId)).ios.find(io => io.id === data.zoneIoId).name;
              message = `Set zone IO device "${ioName}" setting "${key}" to "${value}"`;
              break;
            }
            case 'ZONE_AUTOMATION': {
              const automationName = zones.find(zone => zone.automations.some(automation => automation.id === id)).automations.find(automation => automation.id === id).name;
              switch (action) {
                case 'CREATE': {
                  message = `Add a zone automation "${data.name}"`;
                  break;
                }
                case 'UPDATE': {
                  message = `Set zone automation "${automationName}" settings`;
                  break;
                }
                case 'REMOVE': {
                  message = `Remove a zone automation "${automationName}"`;
                  break;
                }
              }
              break;
            }
            case 'ZONE_AUTOMATION_SETTING': {
              const automationName = zones.find(zone => zone.automations.some(automation => automation.id === id)).automations.find(automation => automation.id === id).name;
              message = `Set zone automation "${automationName}" setting "${key}" to "${value}"`;
              break;
            }
          }

          return (
            <List.Item key={i}>
              <List.Item.Meta
                avatar={<Avatar icon={{
                  CREATE: <PlusOutlined />,
                  UPDATE: <EditOutlined />,
                  REMOVE: <DeleteOutlined />,
                }[action]}/>}
                title={message}
              />
            </List.Item>
          );
        }}
      />
    </Modal>
  );
};