import React, { useState, useEffect } from 'react';

import { Alert, Modal, Form, Input, Button, Progress, Spin, message, Divider } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    sm: { offset: 5, span: 16 },
  },
};

export default function StationZoneCloneModal(props) {
  const {
    stationId,
    zoneId,
    zone,
    visible,
    onUpdate,
    onOk,
    onCancel,
  } = props;

  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    const addingIos = [];
    const addingAutomations = [];

    // ios
    for (let stationZoneIo of zone.ios) {
      const addingIo = {
        originalIoId: stationZoneIo.id,
        name: stationZoneIo.name,
        type: stationZoneIo.type,
        settings: stationZoneIo.settings,
        ioDevices: [],
      };

      for (let stationZoneIoDevice of stationZoneIo.ioDevices) {
        const ioDevice = {
          originalZoneIoId: stationZoneIoDevice.zoneIoId,
          deviceId: stationZoneIoDevice.deviceId,
          index: stationZoneIoDevice.index,
          settings: stationZoneIoDevice.settings,
        };

        addingIo.ioDevices.push(ioDevice);
      }

      addingIos.push(addingIo);
    }

    // automations
    for (let stationZoneAutomation of zone.automations) {
      const addingAutomation = {
        originalIoId: stationZoneAutomation.id,
        name: stationZoneAutomation.name,
        type: stationZoneAutomation.type,
        isEnabled: stationZoneAutomation.isEnabled,
        settings: stationZoneAutomation.settings,
      };

      addingAutomations.push(addingAutomation);
    }

    // adding...
    // zone
    const tempZoneId = `#${Math.random().toString().slice(2)}`;

    onUpdate({
      name: 'ZONE',
      action: 'CREATE',
      id: tempZoneId,
      data: {
        name: values.name,
        isEnabled: false,
      },
    });

    // ios
    for (let addingIo of addingIos) {
      const tempZoneIoId = `#${Math.random().toString().slice(2)}`;
      onUpdate({
        name: 'ZONE_IO',
        action: 'CREATE',
        id: tempZoneIoId,
        data: {
          zoneId: tempZoneId,
          name: addingIo.name,
          type: addingIo.type,
        },
      });

      addingIo.id = tempZoneIoId;

      // settings
      for (let key in addingIo.settings) {
        onUpdate({
          name: 'ZONE_IO_SETTING',
          action: 'UPDATE',
          id: tempZoneIoId,
          key,
          value: addingIo.settings[key],
        });
      }

      // ioDevices
      for (let ioDevice of addingIo.ioDevices) {
        const tempZoneIoDeviceId = `#${Math.random().toString().slice(2)}`;

        onUpdate({
          name: 'ZONE_IO_DEVICE',
          action: 'CREATE',
          id: tempZoneIoDeviceId,
          data: {
            zoneIoId: tempZoneIoId,
            deviceId: ioDevice.deviceId,
            index: ioDevice.index,
          },
        });

        ioDevice.id = tempZoneIoDeviceId;

        // settings
        for (let key in ioDevice.settings) {
          onUpdate({
            name: 'ZONE_IO_DEVICE_SETTING',
            action: 'UPDATE',
            id: tempZoneIoDeviceId,
            key,
            value: ioDevice.settings[key],
            data: {
              zoneIoId: tempZoneIoId,
            },
          });
        }
      }
    }

    // automations
    for (let addingAutomation of addingAutomations) {
      const tempZoneAutomationId = `#${Math.random().toString().slice(2)}`;
      onUpdate({
        name: 'ZONE_AUTOMATION',
        action: 'CREATE',
        id: tempZoneAutomationId,
        data: {
          zoneId: tempZoneId,
          name: addingAutomation.name,
          type: addingAutomation.type,
          isEnabled: addingAutomation.isEnabled,
        },
      });

      addingAutomation.id = tempZoneAutomationId;

      // settings
      for (let key in addingAutomation.settings) {
        let value = addingAutomation.settings[key];

        if (key.match(/IoId$/)) {
          const foundIo = addingIos.find(addingIo => addingIo.originalIoId === value);
          if (foundIo) {
            value = foundIo.id;
          }
        }

        onUpdate({
          name: 'ZONE_AUTOMATION_SETTING',
          action: 'UPDATE',
          id: tempZoneAutomationId,
          key,
          value,
        });
      }
    }

    onOk();
  };

  return (
    <Modal
      title={zoneId ? `Clone Zone (${zoneId.substr(0, 8)})` : ''}
      visible={visible}
      onOk={onOk}
      onCancel={!loading ? onCancel : () => {}}
      width="80%"
      footer={null}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          onFinish={onFinish}
        >
          <Form.Item
            label="Original Zone"
          >
            {zone ? zone.name : null}
          </Form.Item>

          <Form.Item
            label="New Zone Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the new zone name',
              },
            ]}
          >
            <Input
              placeholder="New Zone Name"
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};