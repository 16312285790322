import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Form, Input, Button, Switch, Select, Spin, message, Divider } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    sm: { offset: 5, span: 16 },
  },
};

const { Option } = Select;

export default function StationZoneIoDeviceEditModal(props) {
  const {
    stationDevices,
    stationId,
    zoneId,
    zoneIoId,
    zoneIoDeviceId,
    zoneIoDevice = {},
    zoneIoDeviceConfig,
    visible,
    onUpdate,
    onOk,
    onCancel,
  } = props;
  const stationDeviceConfigsData = useSelector(state => state.stationDeviceConfigs);

  const [loading, setLoading] = useState(false);

  const device = zoneIoDevice && zoneIoDevice.deviceId ? stationDevices.find(stationDevice => stationDevice.id === zoneIoDevice.deviceId) : null;

  const [selectedDeviceId, setSelectedDeviceId] = useState(device ? device.id : null);
  const [stationDeviceSettingsHasChanged, setStationDeviceSettingsHasChanged] = useState({});

  // flatten settings
  const zoneIoDeviceFlatten = (() => {
    const flatten = {
      ...zoneIoDevice,
    };

    if (zoneIoDevice) {
      delete flatten.settings;

      for (let key in zoneIoDevice.settings) {
        flatten[`settings.${key}`] = zoneIoDevice.settings[key];
      }
    }

    return flatten;
  })();

  const stationDevicesSelectable = stationDevices.filter(stationDevice => {
    const stationDeviceConfig = stationDeviceConfigsData.find(deviceConfig => deviceConfig.model === stationDevice.model);
    return stationDeviceConfig.ios.some(io => io.type === zoneIoDeviceConfig.type);
  });

  const onFinish = async (values) => {
    const device = stationDevicesSelectable.find(stationDevice => stationDevice.id === selectedDeviceId);
    const settingConfigs = stationDeviceConfigsData.find(deviceConfig => deviceConfig.model === device.model).ios.find(io => io.type === zoneIoDeviceConfig.type).settings;

    const zoneIoDeviceSettingsObj = {};

    // device settings
    for (let settingConfig of settingConfigs) {
      if (stationDeviceSettingsHasChanged[`settings.${settingConfig.key}`]) {
        let value = values[`settings.${settingConfig.key}`];

        switch (settingConfig.type) {
          case 'option': {
            value = value !== undefined ? value : null;
            break;
          }
          case 'number':
            value = parseFloat(value);
            break;
        }

        zoneIoDeviceSettingsObj[settingConfig.key] = value;
      }
    }

    // edit
    if (zoneIoDeviceId) {
      for (let key in zoneIoDeviceSettingsObj) {
        onUpdate({
          name: 'ZONE_IO_DEVICE_SETTING',
          action: 'UPDATE',
          id: zoneIoDeviceId,
          key,
          value: zoneIoDeviceSettingsObj[key],
          data: {
            zoneIoId,
          },
        });
      }
    } else {
      const tempId = `#${Math.random().toString().slice(2)}`;

      onUpdate({
        name: 'ZONE_IO_DEVICE',
        action: 'CREATE',
        id: tempId,
        data: {
          zoneIoId,
          deviceId: selectedDeviceId,
          index: zoneIoDeviceConfig.index,
        },
      });

      for (let key in zoneIoDeviceSettingsObj) {
        onUpdate({
          name: 'ZONE_IO_DEVICE_SETTING',
          action: 'UPDATE',
          id: tempId,
          key,
          value: zoneIoDeviceSettingsObj[key],
          data: {
            zoneIoId,
          },
        });
      }
    }

    onOk();
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (changedFields) {
      setStationDeviceSettingsHasChanged({
        ...stationDeviceSettingsHasChanged,
        [changedFields[0].name[0]]: true,
      });
    }
  };

  const renderSettings = () => {
    const device = stationDevicesSelectable.find(stationDevice => stationDevice.id === selectedDeviceId);
    const settingConfigs = stationDeviceConfigsData.find(deviceConfig => deviceConfig.model === device.model).ios.find(io => io.type === zoneIoDeviceConfig.type).settings;

    return [
      settingConfigs.length ? <Divider key="divider" /> : null,
      ...settingConfigs.map((item, i) => {
        switch (item.type) {
          case 'number': {
            return (
              <Form.Item
                {...formLayout}
                key={item.key}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please enter ${item.title}`,
                  }] : []),
                ]}
              >
                <Input
                  type="number"
                  className={stationDeviceSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                />
              </Form.Item>
            );
          }
          case 'string': {
            return (
              <Form.Item
                {...formLayout}
                key={item.key}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please enter ${item.title}`,
                  }] : []),
                ]}
              >
                <Input
                  className={stationDeviceSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                />
              </Form.Item>
            );
          }
          case 'boolean': {
            return (
              <Form.Item
                {...formLayout}
                key={item.key}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please select ${item.title}`,
                  }] : []),
                ]}
                valuePropName="checked"
              >
                <Switch
                  className={stationDeviceSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                />
              </Form.Item>
            );
          }
          case 'option': {
            return (
              <Form.Item
                {...formLayout}
                key={item.key}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please select ${item.title}`,
                  }] : []),
                ]}
              >
                <Select
                  className={stationDeviceSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                  placeholder="Not selected"
                >
                  {item.options.map((option) => {
                    return (
                      <Option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          }
          default:
            return (
              <span>(${item.key})</span>
            );
        }
      }),
    ];
  };

  return (
    <Modal
      title={zoneIoDeviceId ? `Edit Device (${zoneIoDeviceId.substr(0, 8)})` : 'Add Device'}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={zoneIoDeviceFlatten}
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
        >
          <Form.Item
            label="Device"
            name="deviceId"
            rules={[
              {
                required: true,
                message: 'Please select device',
              },
            ]}
          >
            <Select
              disabled={!!zoneIoDeviceId}
              value={selectedDeviceId}
              onChange={(value) => setSelectedDeviceId(value)}
            >
              {stationDevicesSelectable.map(stationDevice => (
                <Option value={stationDevice.id} key={stationDevice.id}>
                  {stationDevice.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {selectedDeviceId && renderSettings()}

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {zoneIoDeviceId ? 'Update' : 'Submit'}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};