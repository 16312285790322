import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Space, Select, Tabs, Empty, Row, Col, Divider, Card, Spin, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../../tools/ToolRequest';

import StationDashboardInputCard from './StationDashboardInputCard';
import SpinFull from '../../../components/SpinFull';

const { Option } = Select;

export default function StationDashboardInputTab(props) {
  const {
    station,
    zones,
    zoneStatuses,
    reloadingStationZoneStatuses,
    reloadingStationZoneStatusesCountdown,
    reloadStationZoneStatuses,
  } = props;

  return (
    zoneStatuses ? (
      <Spin spinning={reloadingStationZoneStatuses}>
        {zones.map(zone => (
          <Card
            key={zone.id}
            className="card-container"
            style={{marginTop: 16}}
            title={
              <>
                <Button
                  type="link"
                  size="small"
                  icon={<ReloadOutlined />}
                  style={{float: 'right'}}
                  onClick={() => reloadStationZoneStatuses()}
                  disabled={reloadingStationZoneStatuses}
                >
                  <span>{reloadingStationZoneStatusesCountdown}</span>
                </Button>

                <span>{zone.name}</span>
              </>
            }
          >
            <Divider>Sensors</Divider>
            <Row gutter={8}>
              {zone.ios.filter(zoneIo => zoneIo.config.inputs).map(zoneIo => {
                let latestValue = zoneStatuses[zone.id][`latestInputValue[${zoneIo.id}]`];
                if (latestValue === undefined) {
                  latestValue = null;
                }

                let latestInputValueUpdatedAt = zoneStatuses[zone.id][`latestInputValueUpdatedAt[${zoneIo.id}]`];

                if (latestInputValueUpdatedAt && moment().diff(latestInputValueUpdatedAt, 'minute') > 30) {
                  latestValue = null;
                }

                let suffix;
                let min = zoneIo.settings.min === null ? 0 : zoneIo.settings.min;
                let max = zoneIo.settings.max === null ? 0 : zoneIo.settings.max;
                let axisSplitNumber;
                let formatter = v => v;
                let targetValue = zoneIo.settings.targetValue;
                let safePercent = zoneIo.settings.targetValueSafePercent;
                let even = false;

                switch (zoneIo.type) {
                  case 'AIR_HUMIDITY': {
                    suffix = '%';
                    formatter = value => value === null ? '-' : value.toFixed(1);
                    break;
                  }
                  case 'AIR_TEMPERATURE': {
                    suffix = '°C';
                    formatter = value => value === null ? '-' : value.toFixed(1);
                    break;
                  }
                  case 'CO2_PPM': {
                    suffix = 'ppm';
                    formatter = value => value === null ? '-' : value.toFixed(0);
                    break;
                  }
                  case 'EC_VALUE': {
                    suffix = 'us/cm';
                    formatter = value => value === null ? '-' : value.toFixed(0);
                    break;
                  }
                  case 'PH_VALUE': {
                    axisSplitNumber = 14;
                    formatter = value => value === null ? '-' : value.toFixed(1);
                    break;
                  }
                  case 'WATER_LEVEL':
                  case 'WATER_LEVEL_DIGITAL': {
                    axisSplitNumber = 0;
                    formatter = value => ['Low', 'Normal', 'Full'][value] || '-';
                    targetValue = 1.66666;
                    safePercent = .33333;
                    even = true;
                    break;
                  }
                  case 'WATER_TEMPERATURE': {
                    suffix = '°C';
                    formatter = value => value === null ? '-' : value.toFixed(1);
                    break;
                  }
                  case 'DISSOLVED_OXYGEN': {
                    suffix = 'mg/L';
                    formatter = value => value === null ? '-' : value.toFixed(2);
                    break;
                  }
                  case 'OXYGEN_SATURATION': {
                    suffix = '%';
                    formatter = value => value === null ? '-' : value.toFixed(1);
                    break;
                  }
                  case 'OXYGEN_PARTIAL_PRESSURE': {
                    suffix = 'hPa';
                    formatter = value => value === null ? '-' : value.toFixed(0);
                    break;
                  }
                  case 'CAMERA': {
                    return;
                  }
                }

                return (
                  <Col key={zoneIo.id} xs={24} md={6}>
                    <StationDashboardInputCard
                      style={{height: 200}}
                      title={zoneIo.name}
                      suffix={suffix}
                      value={latestValue}
                      min={min}
                      max={max}
                      targetValue={targetValue}
                      safePercent={safePercent}
                      even={even}
                      axisSplitNumber={0}
                      formatter={formatter}
                    />
                  </Col>
                );
              })}
            </Row>
          </Card>
        ))}
      </Spin>
    ) : <SpinFull />
  );
};