import React, { useState, useEffect } from 'react';

import { Statistic } from 'antd';

import '../../../less/StationDashboardOverviewInputCard.less';

export default function StationDashboardOverviewInputCard(props) {
  const {
    title,
    className,
    suffix='',
    min,
    max,
    value,
    targetValue,
    safePercent,
    even=false,
    formatter=(value) => value,
    statusFormatter=null,
  } = props;

  let status = '';

  if (((targetValue !== null && safePercent !== null) || statusFormatter) && value !== null) {
    if (statusFormatter) {
      status = statusFormatter(value);
    } else {
      const range = max - min;
      const targetPercent = targetValue / range;

      const targetLowerWarningValue = targetValue - range * safePercent * (even ? 1.5 : 1);
      const targetLowerSafeValue = targetValue - range * safePercent / 2;
      const targetUpperSafeValue = targetValue + range * safePercent / 2;
      const targetUpperWarningValue = targetValue + range * safePercent * (even ? 1.5 : 1);

      if (value < targetLowerWarningValue || value > targetUpperWarningValue) {
        status = 'danger';
      } else if (value < targetLowerSafeValue || value > targetUpperSafeValue) {
        status = 'warning';
      } else {
        status = 'safe';
      }
    }
  }

  return (
    <div
      className={[
        'overview-input-card',
        className,
      ].join(' ')}
    >
      <Statistic
        title={title}
        value={formatter(value)}
        suffix={suffix}
        groupSeparator=""
        className={[
          status,
        ].join(' ')}
      />
      <div className="icon" />
    </div>
  );
};
