import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';

import { Switch, Button, Tooltip, Badge } from 'antd';
import { NumberOutlined } from '@ant-design/icons';

import StationStatus from './columns/StationStatus';

import * as ToolMath from '../tools/ToolMath';

const renderCopyableText = (value, trimmed = true) => {
  return (
    <CopyToClipboard text={value}>
      <Tooltip title={value}>
        <Button type="dashed" block>
          {trimmed ? value.substr(0, 8) : value}
        </Button>
      </Tooltip>
    </CopyToClipboard>
  );
};

export default function SuperTableColumn(props) {
  const {
    template,
    key,
    title,
    width,
    render,
  } = props;

  return {
    ...props,
    dataIndex: key,
    render: (value, record) => {
      if (render) {
        return render(value, record);
      }

      switch (template) {
        case 'id': {
          return (
            <CopyToClipboard text={value}>
              <Tooltip title={value.substr(0, 8)}>
                <NumberOutlined style={{ fontSize: 11 }} />
              </Tooltip>
            </CopyToClipboard>
          );
        }

        case 'isVisible': {
          return (
            <Switch checked={value} />
          );
        }

        case 'isEnabled': {
          return (
            <Switch checked={value} />
          );
        }

        case 'price': {
          return (
            <div className="price">
              {ToolMath.formatPrice((value / 10000).toFixed(2))}
            </div>
          );
        }

        case 'datetime': {
          return value ? (
            <Tooltip title={moment(value).format('YYYY-MM-DD HH:mm:ss')}>
              {ToolMath.daysFromNow(value)}
            </Tooltip>
          ) : '-';
        }

        case 'date': {
          return value ? (
            <Tooltip title={moment(value).format('YYYY-MM-DD')}>
              {ToolMath.daysFromNow(value)}
            </Tooltip>
          ) : '-';
        }

        case 'stationStatus': {
          return (
            <StationStatus id={record.id} />
          );
        }

        case 'seedPurchaseReadableId':
        case 'seedlingTrayReadableId':
        case 'plantingTrayReadableId': {
          return (
            <Tooltip
              title={{
                'NORMAL': 'Normal',
                'USED_UP': 'Used Up',
                'STALED': 'Staled',
              }[record.status]}
            >
              <Badge
                status={{
                  'NORMAL': 'success',
                  'USED_UP': 'warning',
                  'STALED': 'error',
                }[record.status]}
                text={record.readableId}
              />
            </Tooltip>
          );
        }

        default:
          return value;
      }
    },
  };
};