import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Layout, Dropdown, Menu, Button, message } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, SettingOutlined, EditOutlined, LogoutOutlined } from '@ant-design/icons';

import ChangePasswordModal from './ChangePasswordModal';
import CompanySettingModal from './CompanySettingModal';

import * as ToolRequest from '../../tools/ToolRequest';

const { Header } = Layout;

export default function AppHeader(props) {
  const userData = useSelector(state => state.user);
  const { collapsed, setCollapsed } = props;

  const [changePasswordModal, setChangePasswordModal] = useState(null);
  const [companySettingModal, setCompanySettingModal] = useState(null);

  const logout = () => {
    ToolRequest.request('DELETE', '/v1/auth')
      .then(res => {
        window.location.reload();
      })
      .catch(err => {
        message.error('Unknown errors. Please retry');
      });
  };

  return (
    <>
      <Header id="mainLayoutHeader">
        <div className="right-container">
          <Dropdown
            overlay={(
              <Menu
                onClick={value => {
                  switch (value.key) {
                    case 'company-setting': {
                      setCompanySettingModal({
                        visible: true,
                        key: Math.random().toString(),
                      });
                      break;
                    }
                    case 'change-password': {
                      setChangePasswordModal({
                        visible: true,
                        key: Math.random().toString(),
                      });
                      break;
                    }
                    case 'logout': {
                      logout();
                      break;
                    }
                  }
                }}
              >
                <Menu.Item key="company-setting">
                  <SettingOutlined />
                  Company Settings
                </Menu.Item>
                <Menu.Item key="change-password">
                  <EditOutlined />
                  Change Password
                </Menu.Item>
                <Menu.Item key="logout">
                  <LogoutOutlined />
                  Logout
                </Menu.Item>
              </Menu>
            )}
          >
            <Button>
              <UserOutlined /> {userData.displayName}
            </Button>
          </Dropdown>
        </div>
        <div>
          {collapsed ? (
            <MenuUnfoldOutlined
              className="trigger"
              onClick={() => setCollapsed(false)}
            />
          ) : (
            <MenuFoldOutlined
              className="trigger"
              onClick={() => setCollapsed(true)}
            />
          )}
        </div>
      </Header>

      {changePasswordModal && (
        <ChangePasswordModal
          key={changePasswordModal.key}
          visible={changePasswordModal.visible}
          onOk={() => {
            setChangePasswordModal({
              ...changePasswordModal,
              visible: false,
            });
          }}
          onCancel={() => {
            setChangePasswordModal({
              ...changePasswordModal,
              visible: false,
            });
          }}
        />
      )}

      {companySettingModal && (
        <CompanySettingModal
          key={companySettingModal.key}
          visible={companySettingModal.visible}
          onOk={() => {
            setCompanySettingModal({
              ...companySettingModal,
              visible: false,
            });
          }}
          onCancel={() => {
            setCompanySettingModal({
              ...companySettingModal,
              visible: false,
            });
          }}
        />
      )}
    </>
  );
};
