import React, { useState, useEffect } from 'react';

import { Modal, Button, Form, Tag, Spin, message, Divider } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../../tools/ToolRequest';

import SpinFull from '../../../components/SpinFull';

const formLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    sm: { offset: 8, span: 16 },
  },
};

export default function StationDashboardOutputEditModal(props) {
  const {
    zoneIoId,
    visible,
    onOk,
    onCancel,
  } = props;

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [stationZoneIo, setStationZoneIo] = useState(null);
  const [stationZone, setStationZone] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [isAutomated, setIsAutomated] = useState(false);

  useEffect(() => {
    (async () => {
      // zone io
      const stationZoneIoRes = await ToolRequest.request('GET', `/v1/station-zone-io/${zoneIoId}`);
      setStationZoneIo(stationZoneIoRes);

      // zone
      const stationZoneRes = await ToolRequest.request('GET', `/v1/station-zone/${stationZoneIoRes.zoneId}`);
      setStationZone(stationZoneRes);

      // check zone automations
      const stationZoneAutomationRes = await ToolRequest.request('GET', `/v1/station-zone-automation`, {
        zoneId: stationZoneIoRes.zoneId,
        isEnabled: true,
        count: 1000,
      });

      let isAutomatedNew = false;
      for (let zoneAutomation of stationZoneAutomationRes.data) {
        const stationZoneAutomationSettingRes = await ToolRequest.request('GET', `/v1/station-zone-automation/${zoneAutomation.id}/setting`);

        for (let key in stationZoneAutomationSettingRes) {
          if (key.match(/IoId$/) && stationZoneAutomationSettingRes[key] === zoneIoId) {
            isAutomatedNew = true;
            break;
          }
        }

        if (isAutomatedNew) {
          break;
        }
      }

      setIsAutomated(isAutomatedNew);

      // zone status
      const stationZoneStatusRes = await ToolRequest.request('GET', `/v1/station-zone-status`, {
        zoneId: stationZoneIoRes.zoneId,
      });

      const isActiveLatest = stationZoneStatusRes[`latestOutputValue[${zoneIoId}]`] || false;
      setIsActive(isActiveLatest);

      setLoaded(true);
    })();
  }, []);

  const updateStatus = async () => {
    setLoading(true);

    try {
      await ToolRequest.request('POST', `/v1/station-relay-control`, {
        zoneIoId,
        isActive: !isActive,
      });

      // retry 5 times
      const retryCount = 5;
      for (let i = 0; i < retryCount; ++i) {
        const stationZoneStatusRes = await ToolRequest.request('GET', `/v1/station-zone-status`, {
          zoneId: stationZoneIo.zoneId,
        });

        const isActiveLatest = stationZoneStatusRes[`latestOutputValue[${zoneIoId}]`] || false;

        if (isActiveLatest === !isActive) {
          message.success('Update control status successfully');

          onOk();
          return;
        } else if (i <= retryCount - 1) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }

      setLoading(false);
      message.error('Unable to update control status. Please retry');

    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          case 'STATION_OFFLINE': {
            return message.error('Station is offline currently. Please try again later');
          }
          default:
            return message.error('Unknown errors. Please retry');
        }
      }

      message.error('Unknown error. Please retry');
    }
  };

  const className = (() => {
    if (!stationZoneIo) {
      return null;
    }

    switch (stationZoneIo.type) {
      case 'LED': {
        return 'home-control-card-led';
      }
      case 'MAIN_WATER_PUMP': {
        return 'home-control-card-main-water-pump';
      }
      case 'NUTRITION_PUMP': {
        return 'home-control-card-nutrition-pump';
      }
      case 'INLET_VALVE': {
        return 'home-control-card-inlet-valve';
      }
      case 'FAN': {
        return 'home-control-card-fan';
      }
      case 'CO2_VALVE': {
        return 'home-control-card-co2-valve';
      }
    }
  })();

  return (
    <Modal
      title="Update Control Status"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="60%"
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
          >
            <div className={[
              'status-modal-icon-container',
              className,
            ].join(' ')}>
              <div className="icon" />
            </div>

            <Form.Item
              label="Zone"
            >
              {stationZone.name}
            </Form.Item>

            <Form.Item
              label="IO"
            >
              {stationZoneIo.name}
            </Form.Item>

            <Form.Item
              label="Control Status"
            >
              <Tag
                color={isActive ? 'success' : 'default'}
                icon={isAutomated ? <ExclamationCircleOutlined /> : null}
              >
                {isActive ? 'ON' : 'OFF'}
              </Tag>
            </Form.Item>

            {isAutomated ? (
              <Form.Item
                {...formTailLayout}
                style={{ color: '#FF0000' }}
              >
                This IO is currently controlled by an automation
              </Form.Item>
            ) : (
              <Form.Item
                {...formTailLayout}
              >
                <Button
                  type="primary"
                  onClick={() => updateStatus()}
                >
                  {isActive ? 'Turn OFF' : 'Turn ON'}
                </Button>
              </Form.Item>
            )}
          </Form>
        </Spin>
      ) : <SpinFull />}
    </Modal>
  );
};