import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { stringify } from 'yaml';

import { Modal, Input } from 'antd';

const { TextArea } = Input;

export default function StationSettingExportModal(props) {
  const {
    stationSettings,
    stationDevices,
    zones,
    visible,
    onCancel,
  } = props;

  // copy first
  const data = JSON.parse(JSON.stringify({
    stationSettings,
    stationDevices,
    zones,
  }));

  // clean unused ids
  if (data.stationDevices) {
    for (let stationDevice of data.stationDevices) {
      delete stationDevice.stationId;
    }
  }

  if (data.zones) {
    for (let zone of data.zones) {
      delete zone.stationId;
      if (zone.ios) {
        for (let io of zone.ios) {
          delete io.stationId;
        }
      }
      if (zone.automations) {
        for (let automation of zone.automations) {
          delete automation.stationId;
        }
      }
    }
  }

  return (
    <Modal
      title="Export Saved Settings"
      visible={visible}
      onCancel={onCancel}
      width="80%"
      footer={null}
    >
      <TextArea
        rows={16}
        value={stringify(data).trim()}
      />
    </Modal>
  );
};