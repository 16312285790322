import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import difference from 'lodash/difference';

import { Modal, Form, Input, Button, Table, Select, Spin, message, Divider, Transfer, Tag, Space, Switch, Progress, Alert } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

import StationZoneAutomationEditModalDailyScheduleInput from './StationZoneAutomationEditModalDailyScheduleInput';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    sm: { offset: 5, span: 16 },
  },
};

const { Option } = Select;

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;
      const rowSelection = {
        getCheckboxProps: (item) => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };
      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{
            pointerEvents: listDisabled ? 'none' : undefined,
          }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

export default function StationZoneAutomationBatchEditModal(props) {
  const {
    stationId,
    visible,
    zones,
    onUpdate,
    onOk,
    onCancel,
  } = props;
  const zoneAutomationConfigsData = useSelector(state => state.zoneAutomationConfigs);

  const [loading, setLoading] = useState(false);
  const [zoneAutomationSettingsHasChanged, setZoneAutomationSettingsHasChanged] = useState({});
  const [zoneAutomationSettingsWillUpdate, setZoneAutomationSettingsWillUpdate] = useState({});
  const [selectedZoneAutomationIds, setSelectedZoneAutomationIds] = useState([]);

  const onFinish = async (values) => {

    const updatingKeys = Object.keys(zoneAutomationSettingsWillUpdate).filter(key => zoneAutomationSettingsWillUpdate[key]);

    for (let key of updatingKeys) {
      const automation = zones.find(zone => zone.automations.some(automation => automation.id === selectedZoneAutomationIds[0])).automations.find(automation => automation.id === selectedZoneAutomationIds[0]);
      const settingConfigs = zoneAutomationConfigsData.find(zoneAutomationConfig => zoneAutomationConfig.type === automation.type).settings;
      const settingConfig = settingConfigs.find(settingConfig => settingConfig.key === key);

      let value = values[`settings.${key}`];
      switch (settingConfig.type) {
        case 'string':
        case 'option': {
          value = value !== undefined ? value : null;
          break;
        }
        case 'number':
          value = isNaN(parseFloat(value)) ? null : parseFloat(value);
          break;
      }

      for (let automationId of selectedZoneAutomationIds) {
        onUpdate({
          name: 'ZONE_AUTOMATION_SETTING',
          action: 'UPDATE',
          id: automationId,
          key,
          value,
        });
      }
    }

    onOk();
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (changedFields && changedFields.length) {
      setZoneAutomationSettingsHasChanged({
        ...zoneAutomationSettingsHasChanged,
        [changedFields[0].name[0]]: true,
      });
    }
  };

  const renderSettings = () => {
    const zoneAutomationTypes = [];

    for (let zone of zones) {
      for (let io of zone.automations) {
        if (selectedZoneAutomationIds.indexOf(io.id) > -1) {
          if (zoneAutomationTypes.indexOf(io.type) === -1) {
            zoneAutomationTypes.push(io.type);
          }
        }
      }
    }

    const settingConfigsAll = [];
    for (let zoneAutomationType of zoneAutomationTypes) {
      const settings = zoneAutomationConfigsData.find(zoneAutomationConfig => zoneAutomationConfig.type === zoneAutomationType).settings;
      if (settings) {
        for (let setting of settings) {
          const found = settingConfigsAll.find(settingConfig => settingConfig.key === setting.key);
          if (!found) {
            settingConfigsAll.push(setting);
          }
        }
      }
    }

    const settingConfigs = settingConfigsAll.filter(settingConfig => {
      return !zoneAutomationTypes.some(zoneAutomationType => {
        const settings = zoneAutomationConfigsData.find(zoneAutomationConfig => zoneAutomationConfig.type === zoneAutomationType).settings;
        if (settings) {
          return !settings.some(setting => setting.key === settingConfig.key && setting.type === settingConfig.type && (setting.type !== 'option' || JSON.stringify(setting.options) === JSON.stringify(settingConfig.options)));
        } else {
          return true;
        }
      })
    });

    return [
      settingConfigs.length ? <Divider key="divider" /> : null,
      ...settingConfigs.map((item, i) => {
        const inputComponent = () => {
          switch (item.type) {
            case 'number': {
              return (
                <Form.Item
                  name={`settings.${item.key}`}
                  rules={[
                    ...(item.isRequired && zoneAutomationSettingsWillUpdate[item.key] ? [{
                      required: true,
                      message: `Please enter ${item.title}`,
                    }] : []),
                  ]}
                  initialValue={item.defaultValue}
                >
                  <Input
                    type="number"
                    className={zoneAutomationSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                    disabled={!zoneAutomationSettingsWillUpdate[item.key]}
                  />
                </Form.Item>
              );
            }
            case 'string': {
              return (
                <Form.Item
                  name={`settings.${item.key}`}
                  rules={[
                    ...(item.isRequired && zoneAutomationSettingsWillUpdate[item.key] ? [{
                      required: true,
                      message: `Please enter ${item.title}`,
                    }] : []),
                  ]}
                  initialValue={item.defaultValue}
                >
                  {item.key === 'runDailyScheduleHourMinute' ? (
                    <StationZoneAutomationEditModalDailyScheduleInput
                      className={zoneAutomationSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                      disabled={!zoneAutomationSettingsWillUpdate[item.key]}
                    />
                  ) : (
                    <Input
                      className={zoneAutomationSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                      disabled={!zoneAutomationSettingsWillUpdate[item.key]}
                    />
                  )}
                </Form.Item>
              );
            }
            case 'boolean': {
              return (
                <Form.Item
                  name={`settings.${item.key}`}
                  rules={[
                    ...(item.isRequired && zoneAutomationSettingsWillUpdate[item.key] ? [{
                      required: true,
                      message: `Please select ${item.title}`,
                    }] : []),
                  ]}
                  valuePropName="checked"
                  initialValue={item.defaultValue}
                >
                  <Switch
                    className={zoneAutomationSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                    disabled={!zoneAutomationSettingsWillUpdate[item.key]}
                  />
                </Form.Item>
              );
            }
            case 'option': {
              return (
                <Form.Item
                  name={`settings.${item.key}`}
                  rules={[
                    ...(item.isRequired && zoneAutomationSettingsWillUpdate[item.key] ? [{
                      required: true,
                      message: `Please select ${item.title}`,
                    }] : []),
                  ]}
                  initialValue={item.defaultValue}
                >
                  <Select
                    className={zoneAutomationSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                    placeholder="Not selected"
                    disabled={!zoneAutomationSettingsWillUpdate[item.key]}
                  >
                    {item.options.map((option) => {
                      return (
                        <Option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            }
            case 'ioId': {
              return (
                <Form.Item>
                  <Alert message="Unable to edit this in batch mode" type="warning" />
                </Form.Item>
              );
            }
            default:
              return (
                <span>(${item.key})</span>
              );
          }
        };

        return (
          <Form.Item
            {...formLayout}
            key={item.key}
            label={item.title}
          >
            {inputComponent()}
            {item.type !== 'ioId' && (
              <Switch
                style={{ marginTop: -32 }}
                checkedChildren="Updating"
                unCheckedChildren="Skipping"
                checked={zoneAutomationSettingsWillUpdate[item.key]}
                onChange={(checked) => setZoneAutomationSettingsWillUpdate({
                  ...zoneAutomationSettingsWillUpdate,
                  [item.key]: checked,
                })}
              />
            )}
          </Form.Item>
        );
      }),
    ];
  };

  // construct automations
  const zoneAutomationData = [];
  for (let zone of zones) {
    for (let io of zone.automations) {
      zoneAutomationData.push({
        key: io.id,
        id: io.id,
        type: io.type,
        name: io.name,
        zoneName: zone.name,
      })
    }
  }

  const transferColumns = [
    {
      dataIndex: 'name',
      title: 'Name',
    },
    {
      dataIndex: 'type',
      title: 'Type',
      render: (value) => <Tag>{value}</Tag>,
    },
    {
      dataIndex: 'zoneName',
      title: 'Zone',
      render: (value) => <Tag>{value}</Tag>,
    },
  ];

  return (
    <Modal
      title="Batch Edit Zone Automations"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      <Form
        {...formLayout}
        // initialValues={zoneAutomationFlatten}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
      >
        <Form.Item
          wrapperCol={{ span: 24 }}
        >
          <TableTransfer
            dataSource={zoneAutomationData}
            targetKeys={selectedZoneAutomationIds}
            onChange={nextSelectedZoneAutomationIds => setSelectedZoneAutomationIds(nextSelectedZoneAutomationIds)}
            leftColumns={transferColumns}
            rightColumns={transferColumns}
            filterOption={(inputValue, item) => {
              try {
                const regex = new RegExp(inputValue, 'i');
                return item.name.match(regex) || item.type.match(regex) || item.zoneName.match(regex);
              } catch (err) {}
            }}
            showSearch
          />
        </Form.Item>

        <Spin spinning={loading}>
          {renderSettings()}

          <Form.Item {...formTailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={!selectedZoneAutomationIds.length || !Object.keys(zoneAutomationSettingsWillUpdate).some(key => zoneAutomationSettingsWillUpdate[key])}
            >
              Update
            </Button>
          </Form.Item>
        </Spin>
      </Form>
    </Modal>
  );
};