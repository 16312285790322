import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import { PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, message, DatePicker, Badge, Select, InputNumber } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    sm: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;

export default function FarmSeedPurchaseEditScreen(props) {
  const history = useHistory();
  const { seedPurchaseId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seedPurchase, setSeedPurchase] = useState(null);
  const [site, setSite] = useState(null);

  const { metadata } = props;

  useEffect(() => {
    (async () => {
      const seedPurchaseRes = await ToolRequest.request('GET', `/v1/farm-seed-purchase/${seedPurchaseId}`);
      setSeedPurchase({
        ...seedPurchaseRes,
        siteId: [seedPurchaseRes.siteId],
        purchasedAt: moment(seedPurchaseRes.purchasedAt),
      });

      const siteRes = await ToolRequest.request('GET', `/v1/farm-site/${seedPurchaseRes.siteId}`);
      setSite(siteRes);

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const res = await ToolRequest.request('PUT', `/v1/farm-seed-purchase/${seedPurchaseId}`, {
        name: values.name,
        seedlingDayCount: values.seedlingDayCount,
        plantingDayCount: values.plantingDayCount,
        remarks: values.remarks,
        status: values.status,
      });

      message.success('Update Site successfully');

      history.push('/farm-seed-purchase');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error('Unknown errors. Please retry');
        }
      }

      message.error('Unknown error. Please retry');
    }
  };

  return loaded ? (
    <PageHeader
      title={`Edit Seed Purchase (${seedPurchase.name})`}
      onBack={() => history.push('/farm-seed-purchase')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={seedPurchase}
          onFinish={onFinish}
        >
          <Form.Item
            label="Site"
            name="siteId"
          >
            <Cascader
              options={[
                {
                  value: site.id,
                  label: site.name,
                },
              ]}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name',
              },
            ]}
          >
            <Input
              placeholder="Name"
            />
          </Form.Item>

          <Form.Item
            label="Days of Seedling"
            name="seedlingDayCount"
            rules={[
              {
                required: true,
                message: 'Please enter the days of seedling',
              },
            ]}
          >
            <InputNumber
              placeholder="Days"
              min={1}
            />
          </Form.Item>

          <Form.Item
            label="Days of Planting"
            name="plantingDayCount"
            rules={[
              {
                required: true,
                message: 'Please enter the days of planting',
              },
            ]}
          >
            <InputNumber
              placeholder="Days"
              min={1}
            />
          </Form.Item>

          <Form.Item
            label="Purchase Date"
            name="purchasedAt"
          >
            <DatePicker disabled={true} />
          </Form.Item>

          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: 'Please select the status',
              },
            ]}
          >
            <Select
              allowClear={false}
              options={[
                {
                  value: 'NORMAL',
                  label: <Badge status="success" text="Normal" />,
                },
                {
                  value: 'USED_UP',
                  label: <Badge status="warning" text="Used Up" />,
                },
                {
                  value: 'STALED',
                  label: <Badge status="error" text="Staled" />,
                },
              ]}
              // displayRender={(label, selectedOptions) => label}
            />
          </Form.Item>

          <Form.Item
            label="Remarks"
            name="remarks"
          >
            <Input.TextArea
              placeholder="Remarks"
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
