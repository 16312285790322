import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Tabs, Menu, Dropdown, Button, Modal, Card, Space, Select } from 'antd';
import { EditOutlined, PlusOutlined, CloseOutlined, EllipsisOutlined, FileSearchOutlined } from '@ant-design/icons';

import SpinFull from '../../../components/SpinFull';
import SuperTable from '../../../components/SuperTable';
import StationZoneIoEditModal from './StationZoneIoEditModal';
import StationZoneIoBatchEditModal from './StationZoneIoBatchEditModal';
import StationZoneIoDeviceModal from './StationZoneIoDeviceModal';

const { Option } = Select;

export default function StationZoneIoTab(props) {
  const history = useHistory();
  const {
    station,
    stationDevices,
    zones,
    onUpdate,
  } = props;

  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [tableKey, setTableKey] = useState(Math.random().toString());

  const [editModal, setEditModal] = useState({
    key: Math.random().toString(),
    visible: false,
    zoneIoId: null,
  });

  const [batchEditModal, setBatchEditModal] = useState({
    key: Math.random().toString(),
    visible: false,
  });

  const [editDeviceModal, setEditDeviceModal] = useState({
    key: Math.random().toString(),
    visible: false,
    zoneIoId: null,
  });

  useEffect(() => {
    if (zones && zones.length) {
      setTableKey(Math.random().toString());
    }
  }, [zones]);

  useEffect(() => {
    if (zones && zones.length && (!selectedZoneId || !zones.some(zone => zone.id === selectedZoneId))) {
      setSelectedZoneId(zones[0].id);
    }
  }, [zones, selectedZoneId]);

  const selectedZone = selectedZoneId && zones ? zones.find(zoneItem => zoneItem.id === selectedZoneId) : null;

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        return (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'editDevice': {
                    setEditDeviceModal({
                      ...editDeviceModal,
                      key: Math.random().toString(),
                      visible: true,
                      zoneIoId: record.id,
                    });
                    break;
                  }
                  case 'edit': {
                    setEditModal({
                      ...editModal,
                      key: Math.random().toString(),
                      visible: true,
                      zoneIoId: record.id,
                    });
                    break;
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: 'Confirm to REMOVE zone IO',
                      content: 'Are you sure to remove this zone IO?',
                      onOk: () => {
                        onUpdate({
                          name: 'ZONE_IO',
                          action: 'REMOVE',
                          id: record.id,
                        });
                      },
                    });
                    break;
                  }
                }
              }}>
                <Menu.Item key="edit">
                  <EditOutlined />
                  Edit
                </Menu.Item>
                <Menu.Item key="editDevice">
                  <EditOutlined />
                  Edit Device Settings
                </Menu.Item>
                <Menu.Item key="remove">
                  <CloseOutlined />
                  Remove
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="default"
              shape="round"
            >
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'name',
      title: 'Name',
    },
    {
      key: 'type',
      title: 'Type',
    },
  ];

  const loadDataSource = async (body) => {
    return {
      data: selectedZone ? selectedZone.ios.slice(body.from, body.from + body.count) : [],
      totalCount: selectedZone ? selectedZone.ios.length : 0,
    };
  };

  return (
    station && zones ? (
      <>
        <Card size="small" style={{ marginBottom: 8 }}>
          <Space>
            <span>Zone:</span>
            <Select
              onChange={value => {
                setSelectedZoneId(value);
                setTableKey(Math.random().toString());
              }}
              value={selectedZoneId}
            >
              {zones.map(zone => (
                <Option key={zone.id} value={zone.id}>{zone.name}</Option>
              ))}
            </Select>
          </Space>
        </Card>

        <div className="top-nav">
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setEditModal({
                  ...editModal,
                  key: Math.random().toString(),
                  visible: true,
                  zoneIoId: null,
                });
              }}
            >
              Add Zone IO
            </Button>

            <Button
              type="primary"
              icon={<FileSearchOutlined />}
              onClick={() => {
                setBatchEditModal({
                  ...batchEditModal,
                  key: Math.random().toString(),
                  visible: true,
                });
              }}
            >
              Batch Edit
            </Button>
          </Space>
        </div>

        <SuperTable
          key={tableKey}
          loadDataSource={loadDataSource}
          columns={columns}
          pagination={false}
        />

        <StationZoneIoBatchEditModal
          key={batchEditModal.key}
          stationId={station.id}
          visible={!!batchEditModal.visible}
          zones={zones}
          onUpdate={onUpdate}
          onOk={() => {
            setBatchEditModal({
              ...batchEditModal,
              visible: false,
            });
          }}
          onCancel={() => {
            setBatchEditModal({
              ...batchEditModal,
              visible: false,
            });
          }}
        />

        <StationZoneIoEditModal
          key={editModal.key}
          stationId={station.id}
          zones={zones}
          zoneId={selectedZoneId}
          zoneIoId={editModal.zoneIoId}
          zoneIo={editModal.zoneIoId ? selectedZone.ios.find(zoneIoItem => zoneIoItem.id === editModal.zoneIoId) : null}
          visible={!!editModal.visible}
          onUpdate={onUpdate}
          onOk={() => {
            setEditModal({
              ...editModal,
              visible: false,
            });
          }}
          onCancel={() => {
            setEditModal({
              ...editModal,
              visible: false,
            });
          }}
        />

        {editDeviceModal.zoneIoId && selectedZone.ios.find(zoneIoItem => zoneIoItem.id === editDeviceModal.zoneIoId) && (
          <StationZoneIoDeviceModal
            key={editDeviceModal.key}
            stationDevices={stationDevices}
            stationId={station.id}
            zoneId={selectedZoneId}
            zoneIoId={editDeviceModal.zoneIoId}
            zoneIo={selectedZone ? selectedZone.ios.find(zoneIoItem => zoneIoItem.id === editDeviceModal.zoneIoId) : null}
            onUpdate={onUpdate}
            visible={!!editDeviceModal.visible}
            onOk={() => {
              setEditDeviceModal({
                ...editDeviceModal,
                visible: false,
              });
            }}
            onCancel={() => {
              setEditDeviceModal({
                ...editDeviceModal,
                visible: false,
              });
            }}
          />
        )}
      </>
    ) : <SpinFull />
  );
};