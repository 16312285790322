import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    sm: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;
const { TextArea } = Input;

export default function StationAddScreen(props) {
  const history = useHistory();
  const metadataData = useSelector(state => state.metadata);
  const userData = useSelector(state => state.user);
  const userPermissionsData = useSelector(state => state.userPermissions);

  const isAllowedCrossCompany = ToolPermission.hasPermission(userPermissionsData, 'ui:company', 'get:xc');

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const companyRes = isAllowedCrossCompany ? (await ToolRequest.request('GET', '/v1/company', {
        count: 1000,
      })) : {
        data: [await ToolRequest.request('GET', `/v1/company/${userData.companyId}`)],
      };

      setCompanyOptions(companyRes.data.map(company => ({
        value: company.id,
        label: `${company.displayName} (${company.name})`,
      })));

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const res = await ToolRequest.request('POST', '/v1/station', {
        companyId: values.companyId[0],
        displayName: values.displayName.trim(),
        deviceNo: isNaN(parseInt(values.deviceNo)) || values.deviceNo <= 0 ? null : parseInt(values.deviceNo),
        isEnabled: values.isEnabled,
      });

      message.success('Create Station successfully');

      history.push('/station');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          case 'DUPLICATE_DEVICE_NO':
            return message.error('Device No is duplicate');
          default:
            return message.error('Unknown errors. Please retry');
        }
      }

      message.error('Unknown error. Please retry');
    }
  };

  return loaded ? (
    <PageHeader
      title="Add a Station"
      onBack={() => history.push('/station')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={{
            companyId: isAllowedCrossCompany ? [] : [companyOptions[0].value],
            isEnabled: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Company"
            name="companyId"
            rules={[
              {
                required: isAllowedCrossCompany,
                message: 'Please select a company',
              },
            ]}
          >
            <Cascader
              options={companyOptions}
              disabled={!isAllowedCrossCompany}
            />
          </Form.Item>

          <Form.Item
            label="Name"
            name="displayName"
            rules={[
              {
                required: true,
                message: 'Please enter the name',
              },
            ]}
          >
            <Input
              placeholder="Name"
            />
          </Form.Item>

          <Form.Item
            label="Device No"
            name="deviceNo"
          >
            <Input
              placeholder="Device No"
              type="number"
            />
          </Form.Item>

          <Form.Item
            label="Active"
            name="isEnabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
