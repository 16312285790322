import React, { useState, useEffect } from 'react';

import { Modal, Button, Form, Tag, Spin, message, Divider } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../../tools/ToolRequest';

import SpinFull from '../../../components/SpinFull';

const formLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    sm: { offset: 8, span: 16 },
  },
};

export default function StationDashboardAutomationEditModal(props) {
  const {
    zoneAutomationId,
    visible,
    onOk,
    onCancel,
  } = props;

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [stationZoneAutomation, setStationZoneAutomation] = useState(null);
  const [stationZone, setStationZone] = useState(null);
  const [isActive, setIsActive] = useState(null);

  useEffect(() => {
    (async () => {
      // zone automation
      const stationZoneAutomationRes = await ToolRequest.request('GET', `/v1/station-zone-automation/${zoneAutomationId}`);
      setStationZoneAutomation(stationZoneAutomationRes);

      setIsActive(stationZoneAutomationRes.isEnabled);

      // zone
      const stationZoneRes = await ToolRequest.request('GET', `/v1/station-zone/${stationZoneAutomationRes.zoneId}`);
      setStationZone(stationZoneRes);

      setLoaded(true);
    })();
  }, []);

  const updateStatus = async () => {
    setLoading(true);

    try {
      await ToolRequest.request('PUT', `/v1/station-zone-automation/${zoneAutomationId}`, {
        isEnabled: !isActive,
      });

      message.success('Update zone automation successfully');
      onOk();
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error('Unknown errors. Please retry');
        }
      }

      message.error('Unknown error. Please retry');
    }
  };

  const className = (() => {
    if (!stationZoneAutomation) {
      return null;
    }

    switch (stationZoneAutomation.type) {
      case 'MAIN_WATER_PUMP_BASIC': {
        return 'home-automation-card-main-water-pump-basic';
      }
      case 'NUTRITION_PUMP_BASIC': {
        return 'home-automation-card-nutrition-pump-basic';
      }
      case 'LED_BASIC': {
        return 'home-automation-card-led-basic';
      }
      case 'CAMERA_BASIC': {
        return 'home-automation-card-camera-basic';
      }
      case 'FAN_BASIC': {
        return 'home-automation-card-fan-basic';
      }
      case 'INLET_WATER_BASIC': {
        return 'home-automation-card-inlet-valve-basic';
      }
      case 'CO2_BASIC': {
        return 'home-automation-card-co2-basic';
      }
      case 'PLC_DIRECT': {
        return 'home-automation-card-plc-direct';
      }
    }
  })();

  return (
    <Modal
      title="Update Automation Status"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="60%"
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
          >
            <div className={[
              'status-modal-icon-container',
              className,
            ].join(' ')}>
              <div className="icon" />
            </div>

            <Form.Item
              label="Zone"
            >
              {stationZone.name}
            </Form.Item>

            <Form.Item
              label="IO"
            >
              {stationZoneAutomation.name}
            </Form.Item>

            <Form.Item
              label="Automation Status"
            >
              <Tag
                color={isActive ? 'success' : 'default'}
              >
                {isActive ? 'ON' : 'OFF'}
              </Tag>
            </Form.Item>

            <Form.Item
              {...formTailLayout}
            >
              <Button
                type="primary"
                onClick={() => updateStatus()}
              >
                {isActive ? 'Turn OFF' : 'Turn ON'}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull />}
    </Modal>
  );
};