import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { parseExpression } from 'cron-parser';
import moment from 'moment';

import { Modal, Form, Input, Button, Typography, Space, Tag } from 'antd';
import { BulbOutlined } from '@ant-design/icons';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    sm: { offset: 5, span: 16 },
  },
};

export default function StationZoneAutomationEditModalDailyScheduleInput(props) {
  const {
    value,
    onChange,
    ...restProps
  } = props;

  const [modal, setModal] = useState({
    key: Math.random().toString(),
    visible: false,
    hourValue: '',
    minuteValue: '',
    schedules: [],
  });

  // cron parser
  useEffect(() => {
    try {
      if (modal.minuteValue && modal.hourValue) {
        const interval = parseExpression(`${modal.minuteValue} ${modal.hourValue} * * *`, {
          currentDate: moment().startOf('day').toDate(),
          endDate: moment().startOf('day').add(1, 'day').toDate(),
          iterator: true,
        });

        const schedules = [];
        while (true) {
          try {
            const schedule = interval.next();
            schedules.push(moment(schedule.value.toISOString()).format('HH:mm'));
          } catch (err2) {
            break;
          }
        }

        schedules.sort();

        setModal({
          ...modal,
          schedules,
        });
      } else {
        setModal({
          ...modal,
          schedules: [],
        });
      }
    } catch (err) {
      setModal({
        ...modal,
        schedules: [],
      });
    }
  }, [modal.minuteValue, modal.hourValue]);

  return (
    <>
      <Space
        style={{ display: 'flex' }}
        direction="vertical"
        size={2}
      >
        <Input
          {...restProps}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        <Button
          type="dashed"
          icon={<BulbOutlined />}
          disabled={restProps.disabled}
          onClick={() => {
            setModal({
              key: Math.random().toString(),
              visible: true,
              hourValue: '',
              minuteValue: '',
              schedules: [],
            });
          }}
        >
          Quick Enter
        </Button>
      </Space>

      <Modal
        key={modal.key}
        title="Daily Schedule Quick Enter"
        visible={modal.visible}
        onOk={() => {
          onChange(modal.schedules.join(','));

          setModal({
            ...modal,
            visible: false,
          });
        }}
        onCancel={() => {
          setModal({
            ...modal,
            visible: false,
          });
        }}
        width="60%"
        maskClosable={false}
      >
        <Form>
          <Form.Item
            {...formLayout}
            label="Hours"
            rules={[
              {
                required: true,
                message: `Please enter Hours`,
              },
            ]}
            extra="e.g. * for every hour; */2 for every 2 hours; 0,12 for midnight and noon"
          >
            <Input
              value={modal.hourValue}
              onChange={(e) => setModal({
                ...modal,
                hourValue: e.target.value.replace(/[^0-9\-,\*\/]/g, ''),
              })}
            />
          </Form.Item>

          <Form.Item
            {...formLayout}
            label="Minutes"
            rules={[
              {
                required: true,
                message: `Please enter Minutes`,
              },
            ]}
            extra="e.g. * for every minute; */2 for every 2 minutes; 0,30 for every 30 minutes"
          >
            <Input
              value={modal.minuteValue}
              onChange={(e) => setModal({
                ...modal,
                minuteValue: e.target.value.replace(/[^0-9\-,\*\/]/g, ''),
              })}
            />
          </Form.Item>

          <Form.Item
            {...formLayout}
            label="Preview Schedule"
          >
            <div>It will run {modal.schedules.length} times in a day</div>

            <div>
              {modal.schedules.map((schedule, i) => (
                <Tag key={i}>{schedule}</Tag>
              ))}
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};