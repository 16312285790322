import moment from 'moment';

export const formatPrice = (value, noPrefix) => {
  var parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return (!noPrefix ? '$' : '') + parts.join('.');
};

export const daysFromNow = (value, noPrePostFix = false) => {
  const days = moment({ hours: 0 }).diff(value, 'days');
  return days < 0 ? `${noPrePostFix ? '' : 'in '}${days} days` : (days > 0 ? `${days} days${noPrePostFix ? '' : ' ago'}` : 'Today');
};

export const daysFromNowNumber = (value) => {
  return moment({ hours: 0 }).diff(value, 'days');
};
