import React, { useState, useEffect } from 'react';

import '../../../less/StationDashboardOutputCard.less';

export default function StationDashboardOutputCard(props) {
  const {
    value,
    text,
    className,
    onClick,
  } = props;

  return (
    <div
      className={[
        'station-dashboard-output-card-container',
        value ? 'active' : '',
        onClick ? 'clickable' : '',
      ].join(' ')}
      onClick={onClick}
    >
      <div className={className}>
        <div className="icon"></div>
        <div className="text">{text}</div>
      </div>
    </div>
  );
};
