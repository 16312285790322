import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Form, Input, Button, Spin, Select, Cascader, Typography, Divider, Switch, message } from 'antd';

import * as Action from '../../actions';
import * as ToolRequest from '../../tools/ToolRequest';
import SpinFull from '../../components/SpinFull';

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const companySettingConfigs = [
  {
    title: 'Farm Site Management',
    items: [
      {
        title: 'Farm Site Management Enabled',
        key: 'featureSiteEnabled',
        type: 'boolean',
      },
      {
        title: 'Seed Purchase Readable ID Format',
        key: 'formatSeedPurchaseReadableId',
        type: 'string',
        description: 'support: {0000} = 4 padding zeros, {date} = 2020-01-02',
      },
      {
        title: 'Seedling Tray Readable ID Format',
        key: 'formatSeedlingTrayReadableId',
        type: 'string',
        description: 'support: {0000} = 4 padding zeros',
      },
      {
        title: 'Planting Tray Readable ID Format',
        key: 'formatPlantingTrayReadableId',
        type: 'string',
        description: 'support: {0000} = 4 padding zeros',
      },
    ],
  },
];

const formLayout = {
  labelCol: {
    md: { span: 8 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 8, span: 16 },
  },
};

export default function CompanySettingModal(props) {
  const { visible, onOk, onCancel } = props;
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user);

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companySettings, setCompanySettings] = useState(null);
  const [companySettingsHasChanged, setCompanySettingsHasChanged] = useState({});

  useEffect(() => {
    (async () => {
      const companySettings = await ToolRequest.request('GET', `/v1/company/${userData.companyId}/setting`);

      const companySettingsFormatted = {};
      for (let key in companySettings) {
        let companySettingConfig;
        for (let sectionConfig of companySettingConfigs) {
          const configItem = sectionConfig.items.find(item => item.key === key);
          if (configItem) {
            companySettingConfig = configItem;
            break;
          }
        }

        if (companySettingConfig) {
          switch (companySettingConfig.type) {
            case 'option': {
              companySettingsFormatted[key] = companySettings[key] ? [companySettings[key]] : null;
              break;
            }
            default:
              companySettingsFormatted[key] = companySettings[key];
          }
        } else {
          companySettingsFormatted[key] = companySettings[key];
        }
      }
      setCompanySettings(companySettingsFormatted);

      setLoading(false);
      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    const companySettingsFormatted = {};
    for (let key in companySettingsHasChanged) {
      let companySettingConfig;
      for (let sectionConfig of companySettingConfigs) {
        const configItem = sectionConfig.items.find(item => item.key === key);
        if (configItem) {
          companySettingConfig = configItem;
          break;
        }
      }

      if (companySettingConfig) {
        switch (companySettingConfig.type) {
          case 'option': {
            companySettingsFormatted[key] = values[key] && values[key][0] !== undefined ? values[key][0] : null;
            break;
          }
          case 'number':
            companySettingsFormatted[key] = parseFloat(values[key]);
            break;
          case 'boolean':
            companySettingsFormatted[key] = !!values[key];
            break;
          default:
            companySettingsFormatted[key] = values[key];
        }
      } else {
        companySettingsFormatted[key] = values[key];
      }
    }

    try {
      for (let key in companySettingsFormatted) {
        const res = await ToolRequest.request('PUT', `/v1/company/${userData.companyId}/setting/${key}`, {
          value: companySettingsFormatted[key],
        });
      }

      // reload settings
      const companySettings = await ToolRequest.request('GET', `/v1/company/${userData.companyId}/setting`);
      dispatch(Action.updateCompanySettings(companySettings));

      message.success('Update Company Settings successfully');

      onOk();
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error('Unknown errors. Please retry');
        }
      }

      message.error('Unknown error. Please retry');
    }
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (changedFields.length) {
      setCompanySettingsHasChanged({
        ...companySettingsHasChanged,
        [changedFields[0].name[0]]: true,
      });
    }
  };

  const renderSettings = () => {
    return companySettingConfigs.map((sectionConfig, i) => {
      return (
        <React.Fragment key={`section-${i}`}>
          <Form.Item {...formTailLayout}>
            <Title level={4}>{sectionConfig.title}</Title>
          </Form.Item>

          {sectionConfig.items.map((item, j) => {
            if (item.type === 'divider') {
              return (
                <Divider key={`divider-${i}-${j}`} />
              );
            }

            let valuePropName = 'value';
            switch (item.type) {
              case 'boolean': {
                valuePropName = 'checked';
                break;
              }
            }

            const renderInput = () => {
              switch (item.type) {
                case 'divider': {
                  return (
                    <Divider />
                  );
                }
                case 'number': {
                  return (
                    <Input
                      type="number"
                      className={companySettingsHasChanged[item.key] && 'input-has-changed'}
                    />
                  );
                }
                case 'string': {
                  return (
                    <Input
                      className={companySettingsHasChanged[item.key] && 'input-has-changed'}
                    />
                  );
                }
                case 'option': {
                  return (
                    <Cascader
                      options={item.options.map((option) => {
                        return {
                          key: option.value,
                          value: option.value,
                          label: option.title,
                        };
                      })}
                      className={companySettingsHasChanged[item.key] && 'input-has-changed'}
                      placeholder="Not selected"
                    />
                  );
                }
                case 'boolean': {
                  return (
                    <Switch
                      className={companySettingsHasChanged[item.key] && 'input-has-changed'}
                    />
                  );
                }
                default:
                  return (
                    <span>(${item.key})</span>
                  );
              }
            };

            return (
              <Form.Item
                {...formLayout}
                key={item.key}
                label={item.title}
                name={item.key}
                valuePropName={valuePropName}
                extra={item.description}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please select or enter ${item.title}`,
                  }] : []),
                ]}
              >
                {renderInput()}
              </Form.Item>
            );
          })}
        </React.Fragment>
      );
    });
  };

  return (
    <Modal
      title="Company Settings"
      visible={visible}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      {loaded ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            initialValues={companySettings}
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
          >
            {renderSettings()}

            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                Update
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull />}
    </Modal>
  );
};