import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Layout, Drawer, Menu } from 'antd';

import Logo from '../../images/farmx-logo-white.png';

const { Sider, Content } = Layout;

export default function AppMenu(props) {
  const history = useHistory();

  const { menu = [], collapsed, setCollapsed } = props;

  const [windowSize, setWindowSize] = useState({});

  useEffect(() => {
    window.addEventListener('resize', () => updateWindowSize());
    updateWindowSize();
  }, []);

  const updateWindowSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  const onOpenChange = (item) => {
    setOpenedItem(item[item.length - 1]);
  };

  const getMatchedMenu = (returnParent = false) => {
    for (let item of menu) {
      if (item.sub) {
        for (let sub of item.sub) {
          const regex = new RegExp(`${sub.match}`);
          if (history.location.pathname.match(regex)) {
            return returnParent ? item.key : sub.key;
          }
        }
      } else {
        const regex = new RegExp(`${item.match || item.key}`);
        if (history.location.pathname.match(regex)) {
          return item.key;
        }
      }
    }
    return null;
  };

  const [openedItem, setOpenedItem] = useState(getMatchedMenu(true));

  const renderMenu = () => {
    const selectedItem = getMatchedMenu();

    return (
      <Menu
        selectedKeys = {[selectedItem]}
        openKeys = {[openedItem]}
        theme="dark"
        mode="inline"
        onOpenChange={onOpenChange}
      >
        <div id="appMenulogoContainer">
          <img className="logo" src={Logo} />
        </div>
        {menu.map(item => {
          if (item.sub) {
            return (
              <Menu.SubMenu
                key={item.key}
                title={
                  <span>
                    {item.icon}
                    <span>
                      <span>{item.title}</span>
                    </span>
                  </span>
                }
              >
                {item.sub.map(subItem => {
                  return (
                    <Menu.Item key={subItem.key}>
                      <Link to={subItem.key}>
                        <span>
                          <span>{subItem.title}</span>
                        </span>
                      </Link>
                    </Menu.Item>
                  )
                })}
              </Menu.SubMenu>
            );
          } else if (item.onClick) {
            return (
              <Menu.Item key={item.key}>
                <Link to="" onClick={() => item.onClick()}>
                  {item.icon}
                  <span>
                    <span>{item.title}</span>
                  </span>
                </Link>
              </Menu.Item>
            );
          } else {
            return (
              <Menu.Item key={item.key}>
                <Link to={item.key}>
                  {item.icon}
                  <span>
                    <span>{item.title}</span>
                  </span>
                </Link>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    );
  };

  return windowSize.width <= 767 ? (
    <Drawer
      id="mainLayoutHeaderDrawer"
      placement="left"
      width="auto"
      closable={false}
      visible={!collapsed}
      onClose={() => setCollapsed(true)}
    >
      <Sider
        trigger={null}
      >
        {renderMenu()}
      </Sider>
    </Drawer>
  ) : (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{overflowX: 'hidden'}}
    >
      {renderMenu()}
    </Sider>
  );
};
