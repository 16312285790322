import React, { useState, useEffect }  from 'react';
import { Modal, List, Badge, Avatar } from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

export default function HomeSiteCardSeedlingDetailModal({
  visible = false,
  onCancel = () => {},
  seedlingTrays = [],
}) {
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      const data = [];
      for (let seedlingTray of seedlingTrays) {
        const res = await ToolRequest.request('GET', `/v1/farm-seedling-tray/${seedlingTray.id}`);
        data.push({
          ...res,
          amount: seedlingTray.amount,
        });
      }
      data.sort((a, b) => {
        return a.readableId.localeCompare(b.readableId);
      });
      setData(data);
    })();
  }, []);

  return (
    <Modal
      title={(
        <>
          <span style={{marginRight: 8}}>Seedling Trays</span>
          <Badge count={seedlingTrays.length} />
        </>
      )}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{paddingTop: 4, paddingBottom: 4}}
    >
      {data ? (
        <List
          dataSource={data}
          renderItem={(item, i) => (
            <List.Item
              key={item.id}
              actions={[
                <span>{item.amount} / {item.slotCount}</span>
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar size="small">{i + 1}</Avatar>}
                title={item.readableId}
                description={item.type}
              />
            </List.Item>
          )}
        />
      ) : <SpinFull style={{ height: 80 }} />}
    </Modal>
  );
};
