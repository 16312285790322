import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Layout, message } from 'antd';
import { HomeOutlined, LogoutOutlined, ClusterOutlined, DashboardOutlined,
  UserOutlined, ShopOutlined, MoneyCollectOutlined, SettingOutlined } from '@ant-design/icons';

import * as ToolDevice from '../../tools/ToolDevice';

import AppMenu from './AppMenu';
import AppHeader from './AppHeader';
import AppContent from './AppContent';

import '../../less/App.less';

export default function AppScreen(props) {
  const companySettingsData = useSelector(state => state.companySettings);
  const [menuCollapsed, setMenuCollapsed] = useState(ToolDevice.isMobile());

  const menu = [
    {
      key: '/',
      title: 'Home',
      icon: <DashboardOutlined />,
      match: '^\/$',
    },
    {
      key: '#/station',
      title: 'Stations',
      icon: <ClusterOutlined />,
      sub: [
        {
          key: '/station',
          title: 'Station List',
          match: '^\/station',
        },
      ],
    },
    ...(companySettingsData.featureSiteEnabled ? [
      {
        key: '#/farm-site',
        title: 'Farms',
        icon: <HomeOutlined />,
        sub: [
          {
            key: '/farm-site',
            title: 'Site List',
            match: '^\/farm-site',
          },
          {
            key: '/farm-seed-purchase',
            title: 'Seed Purchases',
            match: '^\/farm-seed-purchase',
          },
          {
            key: '/farm-seedling-tray',
            title: 'Seedling Trays',
            match: '^\/farm-seedling-tray',
          },
          {
            key: '/farm-planting-tray',
            title: 'Planting Trays',
            match: '^\/farm-planting-tray',
          },
          {
            key: '/farm-seedling-record',
            title: 'Seedling Records',
            match: '^\/farm-seedling-record',
          },
          {
            key: '/farm-planting-record',
            title: 'Planting Records',
            match: '^\/farm-planting-record',
          },
          {
            key: '/farm-harvest-record',
            title: 'Harvesting Records',
            match: '^\/farm-harvest-record',
          },
        ],
      },
    ] : []),
  ];

  return (
    <Layout id="mainLayout">
      <AppMenu
        collapsed={menuCollapsed}
        setCollapsed={setMenuCollapsed}
        menu={menu}
      />
      <Layout>
        <AppHeader
          collapsed={menuCollapsed}
          setCollapsed={setMenuCollapsed}
        />
        <AppContent />
      </Layout>
    </Layout>
  );
}
