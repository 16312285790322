import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import { PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, message, InputNumber } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    sm: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;

export default function FarmSeedlingRecordEditScreen(props) {
  const history = useHistory();
  const { seedlingRecordId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seedlingRecord, setSeedlingRecord] = useState(null);
  const [site, setSite] = useState(null);
  const [seedPurchase, setSeedPurchase] = useState(null);
  const [seedlingTray, setSeedlingTray] = useState(null);

  const { metadata } = props;

  useEffect(() => {
    (async () => {
      const seedlingRecordRes = await ToolRequest.request('GET', `/v1/farm-seedling-record/${seedlingRecordId}`);
      setSeedlingRecord({
        ...seedlingRecordRes,
        siteId: [seedlingRecordRes.siteId],
        seedPurchaseId: [seedlingRecordRes.seedPurchaseId],
        seedlingTrayId: [seedlingRecordRes.seedlingTrayId],
      });

      const siteRes = await ToolRequest.request('GET', `/v1/farm-site/${seedlingRecordRes.siteId}`);
      setSite(siteRes);

      const seedPurchaseRes = await ToolRequest.request('GET', `/v1/farm-seed-purchase/${seedlingRecordRes.seedPurchaseId}`);
      setSeedPurchase(seedPurchaseRes);

      const seedlingTrayRes = await ToolRequest.request('GET', `/v1/farm-seedling-tray/${seedlingRecordRes.seedlingTrayId}`);
      setSeedlingTray(seedlingTrayRes);

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const res = await ToolRequest.request('PUT', `/v1/farm-seedling-record/${seedlingRecordId}`, {
        amount: values.amount,
      });

      message.success('Update Seedling Record successfully');

      history.push('/farm-seedling-record');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error('Unknown errors. Please retry');
        }
      }

      message.error('Unknown error. Please retry');
    }
  };

  return loaded ? (
    <PageHeader
      title={`Edit Seedling Record (${seedlingRecordId})`}
      onBack={() => history.push('/farm-seedling-record')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={seedlingRecord}
          onFinish={onFinish}
        >
          <Form.Item
            label="Site"
            name="siteId"
          >
            <Cascader
              options={[
                {
                  value: site.id,
                  label: site.name,
                },
              ]}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            label="Seed Purchase"
            name="seedPurchaseId"
          >
            <Cascader
              options={[
                {
                  value: seedPurchase.id,
                  label: seedPurchase.readableId,
                },
              ]}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            label="Seedling Tray"
            name="seedlingTrayId"
          >
            <Cascader
              options={[
                {
                  value: seedlingTray.id,
                  label: seedlingTray.readableId,
                },
              ]}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            label="No. of Seedlings"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please enter the no. of seedlings',
              },
            ]}
          >
            <InputNumber
              placeholder="1"
              min={1}
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
