import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import { PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, message, InputNumber } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    sm: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;

export default function FarmHarvestRecordEditScreen(props) {
  const history = useHistory();
  const { harvestRecordId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [harvestRecord, setHarvestRecord] = useState(null);
  const [site, setSite] = useState(null);
  const [plantingTray, setPlantingTray] = useState(null);

  const { metadata } = props;

  useEffect(() => {
    (async () => {
      const harvestRecordRes = await ToolRequest.request('GET', `/v1/farm-harvest-record/${harvestRecordId}`);
      setHarvestRecord({
        ...harvestRecordRes,
        siteId: [harvestRecordRes.siteId],
        plantingTrayId: [harvestRecordRes.plantingTrayId],
      });

      const siteRes = await ToolRequest.request('GET', `/v1/farm-site/${harvestRecordRes.siteId}`);
      setSite(siteRes);

      const plantingTrayRes = await ToolRequest.request('GET', `/v1/farm-planting-tray/${harvestRecordRes.plantingTrayId}`);
      setPlantingTray(plantingTrayRes);

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const res = await ToolRequest.request('PUT', `/v1/farm-harvest-record/${harvestRecordId}`, {
        amount: values.amount,
        weight: values.weight,
      });

      message.success('Update Harvesting Record successfully');

      history.push('/farm-harvest-record');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error('Unknown errors. Please retry');
        }
      }

      message.error('Unknown error. Please retry');
    }
  };

  return loaded ? (
    <PageHeader
      title={`Edit Harvesting Record (${harvestRecordId})`}
      onBack={() => history.push('/farm-harvest-record')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={harvestRecord}
          onFinish={onFinish}
        >
          <Form.Item
            label="Site"
            name="siteId"
          >
            <Cascader
              options={[
                {
                  value: site.id,
                  label: site.name,
                },
              ]}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            label="Planting Tray"
            name="plantingTrayId"
          >
            <Cascader
              options={[
                {
                  value: plantingTray.id,
                  label: plantingTray.readableId,
                },
              ]}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            label="No. of Plantings"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please enter the no. of plantings',
              },
            ]}
          >
            <InputNumber
              placeholder="1"
              min={1}
            />
          </Form.Item>

          <Form.Item
            label="Weight (g)"
            name="weight"
          >
            <InputNumber
              placeholder="1"
              min={1}
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
