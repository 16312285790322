import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { PageHeader, Dropdown, Menu, Button, Spin, Badge, Modal, Tooltip } from 'antd';
import { EditOutlined, DashboardOutlined, DownOutlined, PlusOutlined, SettingOutlined, EllipsisOutlined, CloseOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';
import SuperTable from '../../components/SuperTable';

export default function FarmHarvestRecordViewScreen(props) {
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [tableKey, setTableKey] = useState(Math.random().toString());
  const [searchSiteOptions, setSearchSiteOptions] = useState([]);

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        return (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    history.push(`/farm-harvest-record/edit/${record.id}`);
                    break;
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: 'Confirm to REMOVE harvesting record',
                      content: 'Are you sure to remove this harvesting record?',
                      onOk: () => {
                        (async () => {
                          await ToolRequest.request('DELETE', `/v1/farm-harvest-record/${record.id}`);

                          setTableKey(Math.random().toString());
                        })();
                      },
                    });
                    break;
                  }
                }
              }}>
                <Menu.Item key="edit">
                  <EditOutlined />
                  Edit
                </Menu.Item>
                <Menu.Item key="remove">
                  <CloseOutlined />
                  Remove
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="default"
              shape="round"
            >
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
    },
    {
      key: 'seedPurchaseName',
      title: 'Seed',
      width: 300,
      render: (value, record) => {
        return (
          <Tooltip title={record.seedPurchaseReadableId}>
            {value}
          </Tooltip>
        );
      },
    },
    {
      key: 'plantingTrayReadableId',
      title: 'Planting Tray',
      width: 280,
    },
    {
      key: 'amount',
      title: 'No. of Crops',
      width: 150,
    },
    {
      key: 'weight',
      title: 'Weight (g)',
      width: 150,
    },
    {
      key: 'siteName',
      title: 'Site',
    },
    {
      template: 'datetime',
      key: 'createdAt',
      title: 'Created At',
    },
  ];

  const searchColumns = [
    {
      template: 'select',
      key: 'siteId',
      title: 'Site',
      options: searchSiteOptions,
      defaultValue: searchSiteOptions[0]?.value,
      allowClear: false,
    },
    {
      template: 'datePicker',
      key: 'beginAt',
      title: 'Created From',
    },
    {
      template: 'datePicker',
      key: 'endAt',
      title: 'Created Until',
    },
  ];

  const searchQueryParams = values => {
    return {
      siteId: values.siteId,
      beginAt: values.beginAt ? values.beginAt.toISOString() : null,
      endAt: values.endAt ? values.endAt.toISOString() : null,
    };
  };

  useEffect(() => {
    (async () => {
      // site
      const siteRes = await ToolRequest.request('GET', '/v1/farm-site', {
        count: 1000,
      });

      setSearchSiteOptions(siteRes.data.map(site => ({
        value: site.id,
        label: site.name,
      })));

      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    const harvestRecordRes = await ToolRequest.request('GET', '/v1/farm-harvest-record', {
      ...body,
    });

    const siteIds = harvestRecordRes.data.filter(harvestRecord => harvestRecord).map(harvestRecord => harvestRecord.siteId).filter((value, index, self) => self.indexOf(value) === index);
    const sites = await Promise.all(siteIds.map(siteId => ToolRequest.request('GET', `/v1/farm-site/${siteId}`).catch(err => {})));

    const seedlingRecordIds = harvestRecordRes.data.filter(harvestRecord => harvestRecord).map(harvestRecord => harvestRecord.seedlingRecordId).filter((value, index, self) => self.indexOf(value) === index);
    const seedlingRecords = await Promise.all(seedlingRecordIds.map(seedlingRecordId => ToolRequest.request('GET', `/v1/farm-seedling-record/${seedlingRecordId}`).catch(err => {})));

    const seedPurchaseIds = seedlingRecords.map(seedlingRecord => seedlingRecord.seedPurchaseId).filter((value, index, self) => self.indexOf(value) === index);
    const seedPurchases = await Promise.all(seedPurchaseIds.map(seedPurchaseId => ToolRequest.request('GET', `/v1/farm-seed-purchase/${seedPurchaseId}`).catch(err => {})));

    const plantingTrayIds = harvestRecordRes.data.filter(harvestRecord => harvestRecord).map(harvestRecord => harvestRecord.plantingTrayId).filter((value, index, self) => self.indexOf(value) === index);
    const plantingTrays = await Promise.all(plantingTrayIds.map(plantingTrayId => ToolRequest.request('GET', `/v1/farm-planting-tray/${plantingTrayId}`).catch(err => {})));

    return {
      data: harvestRecordRes.data.map(harvestRecord => {
        const site = sites.find(site => site && site.id === harvestRecord.siteId);
        const seedlingRecord = seedlingRecords.find(seedlingRecord => seedlingRecord && seedlingRecord.id === harvestRecord.seedlingRecordId);
        const seedPurchase = seedPurchases.find(seedPurchase => seedPurchase && seedPurchase.id === seedlingRecord.seedPurchaseId);
        const plantingTray = plantingTrays.find(plantingTray => plantingTray && plantingTray.id === harvestRecord.plantingTrayId);
        return {
          ...harvestRecord,
          siteName: site?.name || '-',
          seedPurchaseReadableId: seedPurchase?.readableId || '-',
          seedPurchaseName: seedPurchase?.name || '-',
          plantingTrayReadableId: plantingTray?.readableId || '-',
        };
      }),
      totalCount: harvestRecordRes.totalCount,
    };
  };

  return loaded ? (
    <PageHeader
      title="Harvest Record List"
      extra={[
        <Button
          key="1"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push('/farm-harvest-record/add')}
        >
          Add Harvesting Record
        </Button>
      ]}
    >
      <SuperTable
        searchColumns={searchColumns}
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
    </PageHeader>
  ) : <SpinFull />;
};
