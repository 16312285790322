import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import * as serviceWorker from './serviceWorker';
import { HashRouter as Router } from 'react-router-dom';

import SplashScreen from './screens/Splash/SplashScreen';
import RootReducer from './reducers/RootReducer';

import './less/index.less';

const store = createStore(RootReducer, {},
  window.devToolsExtension
  ? compose(
    applyMiddleware(thunk),
    window.devToolsExtension(),
  )
  : compose(
    applyMiddleware(thunk),
  )
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <SplashScreen />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
