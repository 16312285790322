import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { PageHeader, Dropdown, Menu, Button, Spin, Badge, Modal, Tooltip, Descriptions, Steps } from 'antd';
import { EditOutlined, DashboardOutlined, DownOutlined, PlusOutlined, SettingOutlined, EllipsisOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';
import * as ToolMath from '../../tools/ToolMath';

import SpinFull from '../../components/SpinFull';
import SuperTable from '../../components/SuperTable';

export default function FarmSeedlingTrayViewScreen(props) {
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [tableKey, setTableKey] = useState(Math.random().toString());
  const [searchSiteOptions, setSearchSiteOptions] = useState([]);
  const [seedlingTrayRecords, setSeedlingTrayRecords] = useState({});

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        return (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'empty': {
                    Modal.confirm({
                      title: 'Confirm to empty seedling tray',
                      content: 'Are you sure to empty this seedling tray?',
                      onOk: () => {
                        (async () => {
                          await ToolRequest.request('POST', `/v1/farm-seedling-tray/${record.id}/cancel`);

                          setTableKey(Math.random().toString());
                        })();
                      },
                    });
                    break;
                  }
                  case 'edit': {
                    history.push(`/farm-seedling-tray/edit/${record.id}`);
                    break;
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: 'Confirm to REMOVE seedling tray',
                      content: 'Are you sure to remove this seedling tray?',
                      onOk: () => {
                        (async () => {
                          await ToolRequest.request('DELETE', `/v1/farm-seedling-tray/${record.id}`);

                          setTableKey(Math.random().toString());
                        })();
                      },
                    });
                    break;
                  }
                }
              }}>
                <Menu.Item key="empty" disabled={!record.latestSeedlingRecord}>
                  <DeleteOutlined />
                  Empty
                </Menu.Item>
                <Menu.Item key="edit">
                  <EditOutlined />
                  Edit
                </Menu.Item>
                <Menu.Item key="remove">
                  <CloseOutlined />
                  Remove
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="default"
              shape="round"
            >
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
    },
    {
      template: 'seedlingTrayReadableId',
      title: 'ID',
      width: 250,
    },
    {
      key: 'status',
      title: 'Status',
      width: 250,
      render: (value, record) => {
        if (record.latestSeedlingRecord) {
          return (
            <Tooltip
              title={`Seedling at ${moment(record.latestSeedlingRecord.createdAt).format('YYYY-MM-DD HH:mm:ss')}`}
            >
              <Badge
                status="processing"
                text={ToolMath.daysFromNow(record.latestSeedlingRecord.createdAt)}
              />
            </Tooltip>
          );
        } else {
          return (
            <Badge
              status="success"
              text="Idle"
            />
          );
        }
      },
    },
    {
      key: 'type',
      title: 'Type',
    },
    {
      key: 'slotCount',
      title: 'Slot Amount',
    },
    {
      key: 'siteName',
      title: 'Site',
    },
  ];

  const searchColumns = [
    {
      template: 'cascader',
      key: 'siteId',
      title: 'Any Site',
      options: searchSiteOptions,
    },
    {
      template: 'cascader',
      key: 'status',
      title: 'Any Status',
      options: [
        {
          label: (
            <Badge
              status="success"
              text="Available"
            />
          ),
          value: 'NORMAL',
        },
        {
          label: (
            <Badge
              status="processing"
              text="In use"
            />
          ),
          value: 'IN_USE',
        },
        {
          label: (
            <Badge
              status="error"
              text="Staled"
            />
          ),
          value: 'STALED',
        },
      ],
    },
  ];

  const searchQueryParams = values => {
    const res = {
      searchText: values.search ? `%${values.search}%` : undefined,
      siteId: values.siteId ? values.siteId[0] : undefined,
    };

    if (values.status) {
      switch (values.status[0]) {
        case 'NORMAL': {
          res.inUse = false;
          res.status = 'NORMAL';
          break;
        }
        case 'IN_USE': {
          res.inUse = true;
          res.status = 'NORMAL';
          break;
        }
        case 'STALED': {
          res.status = 'STALED';
          break;
        }
      }
    }

    return res;
  };

  useEffect(() => {
    (async () => {
      // site
      const siteRes = await ToolRequest.request('GET', '/v1/farm-site', {
        count: 1000,
      });

      setSearchSiteOptions(siteRes.data.map(site => ({
        value: site.id,
        label: site.name,
      })));

      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    const seedlingTrayRes = await ToolRequest.request('GET', '/v1/farm-seedling-tray', {
      ...body,
    });

    const siteIds = seedlingTrayRes.data.filter(seedlingTray => seedlingTray).map(seedlingTray => seedlingTray.siteId).filter((value, index, self) => self.indexOf(value) === index);
    const sites = await Promise.all(siteIds.map(siteId => ToolRequest.request('GET', `/v1/farm-site/${siteId}`).catch(err => {})));

    return {
      data: seedlingTrayRes.data.map(seedlingTray => {
        const site = sites.find(site => site && site.id === seedlingTray.siteId);
        return {
          ...seedlingTray,
          siteName: site?.name || '-',
        };
      }),
      totalCount: seedlingTrayRes.totalCount,
    };
  };

  const expandedRowRender = (record) => {
    if (record.latestSeedlingRecord) {
      const recordData = seedlingTrayRecords[record.id];
      return recordData ? (
        <div style={{ padding: 16 }}>
          <Descriptions
            size="small"
            labelStyle={{ fontWeight: 'bold' }}
          >
            <Descriptions.Item label="Seed">{recordData.seedPurchase.name}</Descriptions.Item>
            <Descriptions.Item label="Seedling Amount">{recordData.seedlingRecord.amount || '-'}</Descriptions.Item>
          </Descriptions>
          <Steps
            progressDot
            direction="vertical"
            size="small"
            style={{ marginLeft: 16, marginTop: 8 }}
          >
            <Steps.Step
              status="finish"
              title="Seed Purchase"
              description={`purchased at ${moment(recordData.seedPurchase.purchasedAt).format('YYYY-MM-DD')} (${ToolMath.daysFromNow(recordData.seedPurchase.purchasedAt)})`}
            />
            <Steps.Step
              status="process"
              title="Seedling"
              description={`started at ${moment(recordData.seedlingRecord.createdAt).format('YYYY-MM-DD')} (${ToolMath.daysFromNow(recordData.seedlingRecord.createdAt)})`}
            />
            <Steps.Step
              status="wait"
              title="Planting"
            />
            <Steps.Step
              status="wait"
              title="Harvesting"
            />
          </Steps>
        </div>
      ) : (
        <SpinFull style={{ height: 50, justifyContent: 'start', paddingLeft: 16 }} />
      );
    }
  };

  const onExpand = (expanded, record) => {
    if (!seedlingTrayRecords[record.id]) {
      (async () => {
        const res = await ToolRequest.request('GET', `/v1/farm-seedling-tray/${record.id}/record`);
        const seedPurchase = await ToolRequest.request('GET', `/v1/farm-seed-purchase/${res.seedPurchaseId}`);
        const seedlingRecord = await ToolRequest.request('GET', `/v1/farm-seedling-record/${res.seedlingRecordId}`);

        setSeedlingTrayRecords(seedlingTrayRecords => ({
          ...seedlingTrayRecords,
          [record.id]: {
            seedPurchase,
            seedlingRecord,
          },
        }));
      })();
    }
  };

  const rowExpandable = (record) => {
    return record.latestSeedlingRecord;
  };

  return loaded ? (
    <PageHeader
      title="Seedling Tray List"
      extra={[
        <Button
          key="1"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push('/farm-seedling-tray/add')}
        >
          Add Seedling Tray
        </Button>
      ]}
    >
      <SuperTable
        showSearchInput={true}
        searchColumns={searchColumns}
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
        expandable={{
          expandRowByClick: true,
          expandedRowRender,
          onExpand,
          rowExpandable,
        }}
      />
    </PageHeader>
  ) : <SpinFull />;
};
