import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { Form, Input, Button, Typography, Spin, message } from 'antd';

import * as Action from '../../actions';
import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolUser from '../../tools/ToolUser';

import '../../less/LoginScreen.less';
import logo from '../../images/farmx-logo-white.png';

const formLayout = {
  labelCol: {
    sm: { span: 6 },
  },
  wrapperCol: {
    sm: { span: 12 },
  },
}

const formTailLayout = {
  wrapperCol: {
    sm: {
      offset: 6,
      span: 12,
    },
  },
};

const { Title } = Typography;

export default function LoginScreen(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    ToolRequest.request('POST', '/v1/auth/password/verify', {
      companyName: values.companyName,
      userName: values.userName,
      password: values.password,
    })
    .then(async res => {
      message.success('Login successfully');

      await ToolRequest.request('GET', '/v1/user/me')
        .then(async (user) => {
          // ToolUser.updateLocale(user.locale);
          ToolUser.updateLocale('en');

          dispatch(Action.updateUser(user));

          await ToolRequest.request('GET', '/v1/user-permission')
            .then(userPermissions => {
              dispatch(Action.updateUserPermissions(userPermissions));

              if (!Object.keys(userPermissions).length) {
                throw new Error();
              }
            });

          await ToolRequest.request('GET', `/v1/company/${user.companyId}/setting`)
            .then(companySettings => {
              dispatch(Action.updateCompanySettings(companySettings));
            });
        });

      const search = history.location.search.substr(1).split('&');
      const next = search.find(tmp => tmp.match(/^next=/)) || null;
      const nextPath = next ? decodeURIComponent(next.substr(5)) : '/';

      history.replace(!nextPath.match(/^\/login/) ? nextPath : '/');
    })
    .catch(err => {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          case 'USER_NOT_FOUND': {
            return message.error('User not fouund');
          }
          case 'INCORRECT_PASSWORD': {
            return message.error('Incorrect password');
          }
          default:
            return message.error('Unknown errors. Please retry');
        }
      }
      message.error('Unknown errors. Please retry');
    });
  };

  return (
    <Spin spinning={loading}>
      <div id="loginContainer">
        <div>
          <Title level={2} className="title">
            <img src={logo} height={100} style={{marginBottom: 16}} />
          </Title>

          <Form
            {...formLayout}
            onFinish={onFinish}
          >
            <Form.Item
              label="Company Name"
              name="companyName"
              rules={[
                {
                  required: true,
                  message: 'Please enter Company Name',
                },
              ]}
            >
              <Input
                placeholder="Company Name"
              />
            </Form.Item>

            <Form.Item
              label="User Name"
              name="userName"
              rules={[
                {
                  required: true,
                  message: 'Please enter User name',
                },
              ]}
            >
              <Input
                placeholder="User Name"
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please enter Password',
                },
              ]}
            >
              <Input.Password
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};
