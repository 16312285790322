import React, { useState, useEffect } from 'react';

import { Statistic } from 'antd';

import '../../../less/StationDashboardOverviewOutputCard.less';

export default function StationDashboardOverviewOutputCard(props) {
  const {
    title,
    className,
    value,
    onClick,
  } = props;

  return (
    <div
      className={[
        'overview-output-card',
        className,
        value ? 'on' : 'off',
        onClick ? 'clickable' : '',
      ].join(' ')}
      onClick={onClick}
    >
      <Statistic
        title={title}
        value={value ? 'ON' : 'OFF'}
      />
      <div className="icon" />
    </div>
  );
};
