import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, message, DatePicker, InputNumber, Select, Badge } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    sm: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export default function FarmPlantingRecordAddScreen(props) {
  const history = useHistory();
  const formRef = useRef(null);

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [siteId, setSiteId] = useState(null);
  const [siteOptions, setSiteOptions] = useState([]);
  const [seedlingTrays, setSeedlingTray] = useState([]);
  const [plantingTrays, setPlantingTrays] = useState([]);

  const { metadata } = props;

  useEffect(() => {
    (async () => {
      const sitesRes = await ToolRequest.request('GET', '/v1/farm-site', {
        count: 1000,
      });

      setSiteOptions(sitesRes.data.map(site => ({
        value: site.id,
        label: site.name,
      })));

      setLoaded(true);
    })();
  }, []);

  const onChangeSite = (siteId) => {
    setSiteId(siteId);
    searchSeedlingTray('');
    searchPlantingTray('');
  };

  const searchSeedlingTray = (value) => {
    (async () => {
      const seedlingTraysRes = await ToolRequest.request('GET', '/v1/farm-seedling-tray', {
        siteId,
        count: 10,
        searchText: value !== '' ? `%${value}%` : undefined,
        inUse: true,
      });

      setSeedlingTray(seedlingTraysRes.data.sort((a, b) => {
        if (a.readableId < b.readableId) {
          return -1;
        } else if (a.readableId > b.readableId) {
          return 1;
        }
        return 0;
      }));
    })();
  }

  const searchPlantingTray = (value) => {
    (async () => {
      const plantingTrayRes = await ToolRequest.request('GET', '/v1/farm-planting-tray', {
        siteId,
        count: 10,
        searchText: value !== '' ? `%${value}%` : undefined,
        inUse: false,
      });

      setPlantingTrays(plantingTrayRes.data.sort((a, b) => {
        if (a.readableId < b.readableId) {
          return -1;
        } else if (a.readableId > b.readableId) {
          return 1;
        }
        return 0;
      }));
    })();
  }

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const res = await ToolRequest.request('POST', '/v1/farm-planting-record', {
        siteId: values.siteId[0],
        seedlingTrayId: values.seedlingTrayId,
        plantingTrayId: values.plantingTrayId,
        amount: values.amount,
      });

      message.success('Create Planting Record successfully');

      history.push('/farm-planting-record');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          case 'PLANTING_TRAY_IN_USE': {
            return message.error('Planting tray is in use');
          }
          default:
            return message.error('Unknown errors. Please retry');
        }
      }

      message.error('Unknown error. Please retry');
    }
  };

  return loaded ? (
    <PageHeader
      title="Add a Planting Record"
      onBack={() => history.push('/farm-planting-record')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={{}}
          onFinish={onFinish}
          ref={formRef}
        >
          <Form.Item
            label="Site"
            name="siteId"
            rules={[
              {
                required: true,
                message: 'Please select a site',
              },
            ]}
          >
            <Cascader
              options={siteOptions}
              onChange={values => onChangeSite(values[0])}
              placeholder="Select Site"
              allowClear={false}
            />
          </Form.Item>

          <Form.Item
            label="Seedling Tray"
            name="seedlingTrayId"
            rules={[
              {
                required: true,
                message: 'Please select the seedling tray',
              },
            ]}
          >
            <Select
              showSearch
              filterOption={false}
              disabled={!siteId}
              placeholder="Select a seedling tray"
              onSearch={(value) => searchSeedlingTray(value)}
            >
              {seedlingTrays.map(seedlingTray => (
                <Option key={seedlingTray.id} value={seedlingTray.value}>
                  {seedlingTray.readableId}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Planting Tray"
            name="plantingTrayId"
            rules={[
              {
                required: true,
                message: 'Please select the planting tray',
              },
            ]}
          >
            <Select
              showSearch
              filterOption={false}
              disabled={!siteId}
              placeholder="Select a planting tray"
              onSearch={(value) => searchPlantingTray(value)}
              onChange={(value) => {
                const plantingTraySelected = plantingTrays.find(plantingTray => plantingTray.id === value);
                if (plantingTraySelected && plantingTraySelected.slotCount) {
                  formRef.current.setFieldsValue({
                    amount: plantingTraySelected.slotCount,
                  });
                }
              }}
            >
              {plantingTrays.map(plantingTray => (
                <Option key={plantingTray.id} value={plantingTray.value}>
                  {plantingTray.readableId}{plantingTray.slotCount ? ` (${plantingTray.slotCount})` : ''}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="No. of Seedlings"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please enter the no. of seedlings',
              },
            ]}
          >
            <InputNumber
              placeholder="1"
              min={1}
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
