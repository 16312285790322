import React, { useState, useEffect }  from 'react';
import { Card, Row, Col, Statistic, Space, List, Tooltip, Badge, Divider } from 'antd';
import { HomeOutlined, FunnelPlotFilled, FireFilled, InboxOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolMath from '../../tools/ToolMath';

import HomeSiteCard from './HomeSiteCard';
import SpinFull from '../../components/SpinFull';

import '../../less/HomeScreen.less';

export default function HomeScreen(props) {
  const companySettingsData = useSelector(state => state.companySettings);

  const [loaded, setLoaded] = useState(false);
  const [stationStatusAll, setStationStatusAll] = useState(null);
  const [stations, setStations] = useState(null);
  const [sites, setSites] = useState(null);

  useEffect(() => {
    (async () => {
      const stationStatusAll = await ToolRequest.request('GET', '/v1/station-status/all');
      setStationStatusAll(stationStatusAll);

      const stationRes = await ToolRequest.request('GET', '/v1/station', {
        isEnabled: true,
        count: 1000,
      });
      setStations(await Promise.all(stationRes.data.map(async (station) => {
        const statuses = await ToolRequest.request('GET', '/v1/station-status', {
          stationId: station.id,
        });
        return {
          ...station,
          statuses,
        };
      })));

      if (companySettingsData.featureSiteEnabled) {
        const sitesRes = await ToolRequest.request('GET', '/v1/farm-site', {
          count: 1000,
        });
        setSites(sitesRes.data);
      }

      setLoaded(true);
    })();
  }, []);

  return loaded ? (
    <Space direction="vertical" style={{width: '100%'}}>
      <Card>
        <Row>
          <Col span={8}>
            <Statistic title="Online Station" value={stationStatusAll ? `${stationStatusAll.connectingStationCount} / ${stationStatusAll.stationCount}` : '- / -'} />
          </Col>
          {companySettingsData.featureSiteEnabled && (
            <Col span={8}>
              <Statistic title="No. of Farms" value={`${sites.filter(site => site.isEnabled).length} / ${sites.length}`} />
            </Col>
          )}
        </Row>
      </Card>

      <Row gutter={[8, 8]}>
        {stations.map((station) => {
          const {
            socketCurrentConnectionBeginAt,
            isSocketConnecting,
          } = station.statuses;

          return (
            <Col
              md={6}
              sm={8}
              xs={12}
              key={station.id}
            >
              <a
                className="home-station-card"
                href={`#/station/dashboard/${station.id}`}
              >
                <div className="title">{station.displayName}</div>
                <div>
                  <Tooltip title={socketCurrentConnectionBeginAt ? ToolMath.daysFromNow(socketCurrentConnectionBeginAt) : 'Currently offline'}>
                    <Badge status={isSocketConnecting ? 'success' : 'error'} />
                    {isSocketConnecting ? 'Online' : 'Offline'}
                  </Tooltip>
                </div>
              </a>
            </Col>
          );
        })}
      </Row>

      {companySettingsData.featureSiteEnabled && sites.filter(site => site.isEnabled).map(site => {
        return (
          <HomeSiteCard
            key={site.id}
            site={site}
          />
        );
      })}
    </Space>
  ) : <SpinFull />;
};
