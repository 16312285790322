import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Carousel, Card, Row, Col, Button, Empty, Tooltip, Image } from 'antd';

import * as ToolRequest from '../../../tools/ToolRequest';

import SpinFull from '../../../components/SpinFull';

export default function StationDashboardOverviewCamera(props) {
  const {
    station,
    zones,
  } = props;

  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [cameraFiles, setCameraFiles] = useState([]);

  useEffect(() => {
    (async () => {
      const cameraFilesNew = [];

      const beginAt = moment().subtract(7, 'day');

      for (let zone of zones) {
        for (let zoneIo of zone.ios) {
          if (zoneIo.config.inputs && zoneIo.config.inputs.indexOf('IMAGE_INPUT') > -1) {

            const fileRes = await ToolRequest.request('GET', '/v1/station-file', {
              stationId: station.id,
              zoneIoId: zoneIo.id,
              beginAt: beginAt.toISOString(),
              count: 1,
            });

            if (fileRes.data.length) {
              cameraFilesNew.push({
                file: fileRes.data.length ? fileRes.data[0] : null,
                zoneIo,
              });
            }
          }
        }
      }

      setCameraFiles(cameraFilesNew);
      setLoaded(true);
    })();
  }, []);

  return (
    <Card
      id="cameraContainer"
      style={{height: 274}}
    >
      {loaded ? (
        cameraFiles.some(cameraFile => cameraFile.file) ? (
          <Carousel
            autoplay
            dotPosition="top"
          >
            {cameraFiles.map((cameraFile, i) => (
              <div key={i}>
                <Image
                  src={cameraFile.file.url}
                  height={200}
                />
                <div>
                  <Tooltip title={moment(cameraFile.file.capturedAt).format('YYYY-MM-DD HH:mm:ss')}>
                    Captured {moment(cameraFile.file.capturedAt).fromNow()}
                  </Tooltip>
                </div>
              </div>
            ))}
          </Carousel>
        ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No images" />
      ) : <SpinFull />}
    </Card>
  );
};
