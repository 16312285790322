import React, { useState, useEffect } from 'react';

import { Modal, Button, Form, Tag, Spin, message, Divider } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../../tools/ToolRequest';

import SpinFull from '../../../components/SpinFull';
import SuperTable from '../../../components/SuperTable';

const formLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    sm: { offset: 8, span: 16 },
  },
};

export default function StationDashboardAutomationProfilingModal(props) {
  const {
    zones,
    visible,
    onCancel,
  } = props;

  const [loaded, setLoaded] = useState(false);
  const [profilingAutomations, setProfilingAutomations] = useState([]);

  const sorter = (field) => {
    return (a, b) => {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      }
      return 0;
    };
  };

  const delayOnCell = (field) => {
    return (record) => {
      let className = '';
      if (record[field] > 5000) {
        className = 'danger';
      } else if (record[field] > 3000) {
        className = 'warning';
      } else if (!isNaN(record[field])) {
        className = 'safe';
      }
      return {
        className,
      };
    };
  };

  const columns = [
    {
      key: 'zoneName',
      title: 'Zone',
      sorter: sorter('zoneName'),
    },
    {
      key: 'automationName',
      title: 'Automation',
      sorter: sorter('automationName'),
    },
    {
      key: 'automationType',
      title: 'Type',
      sorter: sorter('automationType'),
    },
    {
      key: 'delayMin',
      title: 'Min (ms)',
      width: 100,
      sorter: sorter('delayMin'),
      className: 'level',
      onCell: delayOnCell('delayMin'),
    },
    {
      key: 'delayAverage',
      title: 'Average (ms)',
      width: 120,
      sorter: sorter('delayAverage'),
      className: 'level',
      onCell: delayOnCell('delayAverage'),
      render: (value) => !isNaN(value) ? Math.round(value) : null,
    },
    {
      key: 'delayMax',
      title: 'Max (ms)',
      width: 100,
      sorter: sorter('delayMax'),
      className: 'level',
      onCell: delayOnCell('delayMax'),
    },
  ];

  useEffect(() => {
    (async () => {
      const profilingAutomations = [];

      for (let zone of zones) {
        for (let automation of zone.automations) {
          const profillingRes = await ToolRequest.request('GET', `/v1/station-zone-automation/${automation.id}/profiling`);

          profilingAutomations.push({
            zoneName: zone.name,
            automationName: automation.name,
            automationType: automation.type,
            delayAverage: profillingRes.average,
            delayMin: profillingRes.min,
            delayMax: profillingRes.max,
          });
        }
      }

      setProfilingAutomations(profilingAutomations);

      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    return {
      data: profilingAutomations,
      totalCount: profilingAutomations.length,
    }
  };

  return (
    <Modal
      title="Automation Profiling (past 7 days)"
      visible={visible}
      onCancel={onCancel}
      width="60%"
      footer={null}
    >
      {loaded ? (
        <SuperTable
          className="automation-profiling-container"
          loadDataSource={loadDataSource}
          columns={columns}
          pagination={{
            pageSize: 100000,
            showQuickJumper: false,
            showSizeChanger: false,
            showTotal: null,
            hideOnSinglePage: true,
          }}
          showSorterTooltip={true}
        />
      ) : <SpinFull />}
    </Modal>
  );
};