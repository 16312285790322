import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { PageHeader, Tabs, Button, Select, Card, Space } from 'antd';
import { AppstoreOutlined, DashboardOutlined, BarChartOutlined, LeftOutlined, CameraOutlined, SettingOutlined, ControlOutlined, RobotOutlined, FundProjectionScreenOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import StationDashboardOverviewTab from './StationDashboardOverview/StationDashboardOverviewTab';
import StationDashboardInputTab from './StationDashboardInput/StationDashboardInputTab';
import StationDashboardOutputTab from './StationDashboardOutput/StationDashboardOutputTab';
import StationDashboardAutomationTab from './StationDashboardAutomation/StationDashboardAutomationTab';
import StationDashboardGraphTab from './StationDashboardGraph/StationDashboardGraphTab';
import StationDashboardCameraTab from './StationDashboardCamera/StationDashboardCameraTab';
import StationDashboardOSTab from './StationDashboardOS/StationDashboardOSTab';
import SpinFull from '../../components/SpinFull';

import '../../less/StationDashboardScreen.less';

const { Option } = Select;

export default function StationDashboardScreen(props) {
  const history = useHistory();
  const { stationId } = useParams();
  const zoneIoConfigsData = useSelector(state => state.zoneIoConfigs);

  const [loaded, setLoaded] = useState(false);
  const [station, setStation] = useState(null);
  const [zones, setZones] = useState([]);

  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [selectedZones, setSelectedZones] = useState(null);
  const [selectedZonesKey, setSelectedZonesKey] = useState(Math.random().toString());
  const [selectedZoneStatuses, setSelectedZoneStatuses] = useState(null);

  const [reloadingStationZoneStatuses, setReloadingStationZoneStatuses] = useState(false);
  const [reloadingStationZoneStatusesCountdown, setReloadingStationZoneStatusesCountdown] = useState(60);

  useEffect(() => {
    setLoaded(false);

    (async () => {
      const stationAllRes = await ToolRequest.request('GET', `/v1/station/${stationId}/all`);
      setStation(stationAllRes.station);

      // zones
      const zonesNew = stationAllRes.zones;
      for (let zone of zonesNew) {
        for (let zoneIo of zone.ios) {
          const zoneIoConfig = zoneIoConfigsData.find(zoneIoConfigItem => zoneIoConfigItem.type === zoneIo.type);

          if (!zoneIoConfig || (!zoneIoConfig.inputs && !zoneIoConfig.outputs)) {
            continue;
          }

          zoneIo.config = zoneIoConfig;
        }
      }
      setZones(zonesNew);

      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    if (loaded) {
      const selectedZonesNew = selectedZoneId ? [zones.find(zone => zone.id === selectedZoneId)] : zones;
      setSelectedZones(selectedZonesNew);
      setSelectedZonesKey(Math.random().toString());
    }
  }, [loaded, selectedZoneId, zones]);

  const loadStationStatus = () => {
    (async () => {
      setReloadingStationZoneStatuses(true);

      // zone status
      const zoneStatusesNew = {};

      for (let zone of selectedZones) {
        const zoneStatusesRes = await ToolRequest.request('GET', `/v1/station-zone-status`, {
          zoneId: zone.id,
        });
        zoneStatusesNew[zone.id] = zoneStatusesRes;
      }

      // zone automations
      const selectedZonesNew = [];

      for (let selectedZone of selectedZones) {
        const zoneAutomationsRes = await ToolRequest.request('GET', `/v1/station-zone-automation`, {
          zoneId: selectedZone.id,
          count: 1000,
        });

        selectedZonesNew.push({
          ...selectedZone,
          automations: zoneAutomationsRes.data,
        });
      }

      setSelectedZoneStatuses(zoneStatusesNew);
      setSelectedZones(selectedZonesNew);

      setReloadingStationZoneStatusesCountdown(60);
      setReloadingStationZoneStatuses(false);
    })();
  };

  useEffect(() => {
    if (loaded && selectedZones) {
      loadStationStatus();
    }
  }, [loaded, selectedZonesKey]);

  useEffect(() => {
    let interval;
    if (loaded) {
      interval = setInterval(() => {
        if (!reloadingStationZoneStatuses) {
          setReloadingStationZoneStatusesCountdown(value => {
            if (!value) {
            } else {
              if (value === 1) {
                loadStationStatus();
              }
              return value - 1;
            }
          });
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [loaded, selectedZones, reloadingStationZoneStatuses]);

  return loaded ? (
    <PageHeader
      title={`Station Dashboard (${station ? station.displayName : '...'})`}
      onBack={() => history.push('/station')}
      extra={[
        <Button
          key="setting"
          icon={<SettingOutlined />}
          href={`#/station/setting/${stationId}`}
        >
          Settings
        </Button>
      ]}
    >
      <Card size="small" style={{ marginBottom: 8 }}>
        <Space>
          <span>Zone:</span>
          <Select
            onChange={value => setSelectedZoneId(value)}
            value={selectedZoneId}
          >
            <Option value={null}>All {zones.length} Zones</Option>
            {zones.map(zone => (
              <Option key={zone.id} value={zone.id}>{zone.name}</Option>
            ))}
          </Select>
        </Space>
      </Card>

      {selectedZones ? (
        <Tabs
          defaultActiveKey="overview"
          items={[
            {
              label: (
                <>
                  <AppstoreOutlined />
                  Overview
                </>
              ),
              key: 'overview',
              children: (
                <StationDashboardOverviewTab
                  station={station}
                  zones={selectedZones}
                  zoneStatuses={selectedZoneStatuses}
                  reloadingStationZoneStatuses={reloadingStationZoneStatuses}
                  reloadingStationZoneStatusesCountdown={reloadingStationZoneStatusesCountdown}
                  reloadStationZoneStatuses={() => loadStationStatus()}
                />
              ),
            },
            {
              label: (
                <>
                  <DashboardOutlined />
                  Sensors
                </>
              ),
              key: 'input',
              children: (
                <StationDashboardInputTab
                  station={station}
                  zones={selectedZones}
                  zoneStatuses={selectedZoneStatuses}
                  reloadingStationZoneStatuses={reloadingStationZoneStatuses}
                  reloadingStationZoneStatusesCountdown={reloadingStationZoneStatusesCountdown}
                  reloadStationZoneStatuses={() => loadStationStatus()}
                />
              ),
            },
            {
              label: (
                <>
                  <ControlOutlined />
                  Controls
                </>
              ),
              key: 'output',
              children: (
                <StationDashboardOutputTab
                  station={station}
                  zones={selectedZones}
                  zoneStatuses={selectedZoneStatuses}
                  reloadingStationZoneStatuses={reloadingStationZoneStatuses}
                  reloadingStationZoneStatusesCountdown={reloadingStationZoneStatusesCountdown}
                  reloadStationZoneStatuses={() => loadStationStatus()}
                />
              ),
            },
            {
              label: (
                <>
                  <RobotOutlined />
                  Automations
                </>
              ),
              key: 'automation',
              children: (
                <StationDashboardAutomationTab
                  station={station}
                  zones={selectedZones}
                  reloadingStationZoneStatuses={reloadingStationZoneStatuses}
                  reloadingStationZoneStatusesCountdown={reloadingStationZoneStatusesCountdown}
                  reloadStationZoneStatuses={() => loadStationStatus()}
                />
              ),
            },
            {
              label: (
                <>
                  <BarChartOutlined />
                  Graph
                </>
              ),
              key: 'graph',
              children: (
                <StationDashboardGraphTab
                  station={station}
                  zones={selectedZones}
                />
              ),
            },
            {
              label: (
                <>
                  <CameraOutlined />
                  Camera
                </>
              ),
              key: 'camera',
              children: (
                <StationDashboardCameraTab
                  station={station}
                  zones={selectedZones}
                />
              ),
            },
            {
              label: (
                <>
                  <FundProjectionScreenOutlined />
                  OS
                </>
              ),
              key: 'os',
              children: (
                <StationDashboardOSTab
                  station={station}
                  zones={selectedZones}
                />
              ),
            },
          ]}
        />
      ) : <SpinFull />}
    </PageHeader>
  ) : <SpinFull />;
};
