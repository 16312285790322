import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Spin, Badge, Tooltip, Space } from 'antd';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolMath from '../../tools/ToolMath';

export default function StationStatus(props) {
  const [loaded, setLoaded] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [currentConnectionBeginAt, setCurrentConnectionBeginAt] = useState(null);

  useEffect(() => {
    reload();
  }, []);

  const reload = () => {
    if (!loaded) {
      return;
    }
    setLoaded(false);

    ToolRequest.request('GET', '/v1/station-status', {
      stationId: props.id,
    })
    .then(({isSocketConnecting, socketCurrentConnectionBeginAt}) => {
      setIsConnected(isSocketConnecting);
      setCurrentConnectionBeginAt(socketCurrentConnectionBeginAt);
      setLoaded(true);
    });
  };

  return loaded ? (
    <div onClick={() => reload()}>
      <Tooltip title={currentConnectionBeginAt ? ToolMath.daysFromNow(currentConnectionBeginAt) : 'Currently offline'}>
        <Space>
          <Badge status={isConnected ? 'success' : 'error'} />
          {isConnected ? 'Online' : 'Offline'}
        </Space>
      </Tooltip>
    </div>
  ) : (
    <Spin size="small" />
  );
};