import React, { useState, useEffect }  from 'react';
import { Card, Row, Col, Badge, List, Image, Space, Tooltip } from 'antd';
import moment from 'moment';
import Moment from 'react-moment';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolMath from '../../tools/ToolMath';

import SpinFull from '../../components/SpinFull';

export default function HomeSiteCardStationListItem(props) {
  const { station } = props;

  const [loaded, setLoaded] = useState(false);
  const [status, setStatus] = useState(null);
  const [cameras, setCameras] = useState(null);

  useEffect(() => {
    (async () => {
      const stationStatus = await ToolRequest.request('GET', `/v1/station-status`, {
        stationId: station.id,
      });
      setStatus(stationStatus);

      const devicesRes = await ToolRequest.request('GET', `/v1/station-device`, {
        stationId: station.id,
        count: 1000,
      });

      const cameras = [];
      for (let device of devicesRes.data) {
        if (device.isEnabled && ['USBCAM', 'IPCAM'].indexOf(device.model) > -1) {
          const deviceSetting = await ToolRequest.request('GET', `/v1/station-device/${device.id}/setting/cameraIndex`);
          cameras.push({
            deviceId: device.id,
            cameraIndex: deviceSetting.cameraIndex,
          });
        }
      }

      for (let camera of cameras) {
        const stationFilesRes = await ToolRequest.request('GET', `/v1/station-file`, {
          stationId: station.id,
          name: 'CAMERA',
          cameraIndex: camera.cameraIndex,
          count: 1,
        });

        camera.file = stationFilesRes.data[0] || null;
      }
      setCameras(cameras);

      setLoaded(true);
    })();
  }, []);

  return (
    <List.Item>
      {loaded ? (
        <Row style={{ width: '100%' }}>
          <Col flex="240px">
            <a href={`#/station/dashboard/${station.id}`}>
              <Badge status={status.isSocketConnecting ? 'success' : 'error'} text={station.displayName} />
            </a>
          </Col>
          <Col flex="auto">
            <div className="image-horizontal-container">
              <Space>
                {cameras.map(camera => {
                  return (
                    <Tooltip title={`#${camera.cameraIndex} (Captured ${ToolMath.daysFromNow(camera.file.capturedAt)})`}>
                      <Image src={camera.file.url} height={120} style={{ objectFit: 'cover' }} />
                    </Tooltip>
                  );
                })}
              </Space>
            </div>
          </Col>
        </Row>
      ) : <SpinFull style={{ width: '100%', height: 22 }} />}
    </List.Item>
  );
};
