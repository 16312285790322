import { combineReducers } from 'redux';

import * as Action from '../actions';

const metadata = (state = null, action) => {
	switch (action.type) {
		case Action.UPDATE_METADATA:
			return action.metadata;
		default:
			return state;
	}
};

const user = (state = null, action) => {
	switch (action.type) {
		case Action.UPDATE_USER:
			return action.user;
		default:
			return state;
	}
};

const companySettings = (state = null, action) => {
	switch (action.type) {
		case Action.UPDATE_COMPANY_SETTINGS:
			return action.companySettings;
		default:
			return state;
	}
};

const userPermissions = (state = {}, action) => {
	switch (action.type) {
		case Action.UPDATE_USER_PERMISSIONS:
			return action.userPermissions;
		default:
			return state;
	}
};

const zoneIoConfigs = (state = {}, action) => {
	switch (action.type) {
		case Action.UPDATE_ZONE_IO_CONFIGS:
			return action.zoneIoConfigs;
		default:
			return state;
	}
};

const stationSettingConfigs = (state = {}, action) => {
	switch (action.type) {
		case Action.UPDATE_STATION_SETTING_CONFIGS:
			return action.stationSettingConfigs;
		default:
			return state;
	}
};

const stationDeviceConfigs = (state = {}, action) => {
	switch (action.type) {
		case Action.UPDATE_STATION_DEVICE_CONFIGS:
			return action.stationDeviceConfigs;
		default:
			return state;
	}
};

const zoneAutomationConfigs = (state = {}, action) => {
	switch (action.type) {
		case Action.UPDATE_ZONE_AUTOMATION_CONFIGS:
			return action.zoneAutomationConfigs;
		default:
			return state;
	}
};

export default combineReducers({
	metadata,
	user,
	companySettings,
	userPermissions,
	zoneIoConfigs,
	stationSettingConfigs,
	stationDeviceConfigs,
	zoneAutomationConfigs,
});