import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Tabs, Row, Col, Statistic, Spin, Button, Card, Progress, Modal, message } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../../tools/ToolRequest';

export default function StationDashboardOSTab(props) {
  const {
    station,
  } = props;

  const [stationStatus, setStationStatus] = useState(null);
  const [reloadingStationStatuses, setReloadingStationStatuses] = useState(false);

  const loadStationStatus = () => {
    (async () => {
      setReloadingStationStatuses(true);

      // station status
      const stationStatus = await ToolRequest.request('GET', '/v1/station-status', {
        stationId: station.id,
      });
      setStationStatus(stationStatus);

      setReloadingStationStatuses(false);
    })();
  };

  useEffect(() => {
    loadStationStatus();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      loadStationStatus();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Spin spinning={reloadingStationStatuses}>
      <Row gutter={[8, 8]} id="dashboardOSContainer">
        <Col xs={24} md={12}>
          <Row gutter={[8, 8]}>
            <Col xs={24}>
              <Card className="card-container">
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={12}>
                    <Statistic
                      title="CPU model"
                      value={stationStatus && stationStatus.osCpuModel || '-'}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <Statistic
                      title="CPU cores"
                      value={stationStatus && stationStatus.osCpuTotalCores || '-'}
                    />
                  </Col>
                  <Col xs={24}>
                    <Statistic
                      title="CPU usage"
                      value={stationStatus && stationStatus.osCpuUsage !== null ? stationStatus.osCpuUsage : '-'}
                      suffix="%"
                    />
                    {stationStatus && stationStatus.osCpuUsage !== null && (
                      <Progress percent={stationStatus.osCpuUsage} showInfo={false} />
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={24}>
              <Card className="card-container">
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={12}>
                    <Statistic
                      title="Platform"
                      value={stationStatus && stationStatus.osMiscPlatformName || '-'}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <Statistic
                      title="Platform type"
                      value={stationStatus && stationStatus.osMiscPlatformType || '-'}
                    />
                  </Col>
                  <Col xs={24}>
                    <Statistic
                      title="Platform arch"
                      value={stationStatus && stationStatus.osMiscPlatformArch || '-'}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={24}>
              <Card className="card-container">
                <Row gutter={[8, 8]}>
                  <Col xs={24}>
                    <Statistic
                      title="App Version"
                      groupSeparator=""
                      value={stationStatus && stationStatus.osAppVersion ? stationStatus.osAppVersion : '-'}
                    />
                  </Col>
                  <Col xs={24}>
                    <Statistic
                      title="Uptime"
                      value={stationStatus && stationStatus.osMiscUptimeDate ? moment(stationStatus.osMiscUptimeDate).format('YYYY-MM-DD HH:mm:ss') : '-'}
                    />
                  </Col>
                  <Col xs={24}>
                    <Statistic
                      title="Process uptime"
                      value={stationStatus && stationStatus.osMiscProcessUptimeDate ? moment(stationStatus.osMiscProcessUptimeDate).format('YYYY-MM-DD HH:mm:ss') : '-'}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={12}>
          <Row gutter={[8, 8]}>
            <Col xs={24}>
              <Card className="card-container">
                <Row gutter={[8, 8]}>
                  <Col xs={24}>
                    <Statistic
                      title="Storage usage"
                      value={stationStatus && stationStatus.osDriveFree !== null && stationStatus.osDriveTotal !== null ? `${(stationStatus.osDriveTotal - stationStatus.osDriveFree)} / ${stationStatus.osDriveTotal}` : '-'}
                      suffix="MB"
                    />
                    {stationStatus && stationStatus.osDriveFree !== null && stationStatus.osDriveTotal !== null && (
                      <Progress percent={Math.floor((stationStatus.osDriveTotal - stationStatus.osDriveFree) / stationStatus.osDriveTotal * 100)} />
                    )}
                  </Col>
                  <Col xs={24}>
                    <Statistic
                      title="Memory usage"
                      value={stationStatus && stationStatus.osMemFree !== null && stationStatus.osMemTotal !== null ? `${stationStatus.osMemTotal - stationStatus.osMemFree} / ${stationStatus.osMemTotal}` : '-'}
                      suffix="MB"
                    />
                    {stationStatus && stationStatus.osMemFree !== null && stationStatus.osMemTotal !== null && (
                      <Progress percent={Math.floor((stationStatus.osMemTotal - stationStatus.osMemFree) / stationStatus.osMemTotal * 100)} />
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={24}>
              <Card className="card-container">
                <Row gutter={[8, 8]}>
                  <Col xs={24}>
                    <Statistic
                      title="VPN connected"
                      value={stationStatus && stationStatus.osNetstatVpnConnected ? 'Yes' : 'No'}
                    />
                  </Col>
                  <Col xs={24}>
                    <Statistic
                      title="Server ping"
                      value={stationStatus && stationStatus.osNetstatCloudPinged && stationStatus.osNetstatCloudPingTime !== null ? stationStatus.osNetstatCloudPingTime : '-'}
                      suffix="ms"
                    />
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={24}>
              <Card className="card-container">
                <Row gutter={[8, 8]}>
                  <Col xs={24}>
                    <Button
                      type="primary"
                      danger
                      icon={<PoweroffOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title: 'Reboot device',
                          content: 'Are you sure to reboot now?',
                          onOk: () => {
                            (async () => {
                              try {
                                await ToolRequest.request('POST', `/v1/station-reboot-system`, {
                                  stationId: station.id,
                                });

                                message.success('Send reboot command successfully');
                              } catch (err) {
                                if (err?.response?.data) {
                                  switch (err.response.data) {
                                    case 'STATION_OFFLINE': {
                                      return message.error('Station is offline currently. Please try again later');
                                    }
                                    default: {
                                      return message.error('Unknown errors. Please retry');
                                    }
                                  }
                                }

                                message.error('Unknown error. Please retry');
                              }
                            })();
                          },
                        });
                      }}
                    >
                      Reboot Device
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};