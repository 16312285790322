import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Tabs, Row, Col, Button, Card, Image, Tooltip, DatePicker, Slider, Space, Empty } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../../tools/ToolRequest';

import SpinFull from '../../../components/SpinFull';

export default function StationDashboardCameraTab(props) {
  const {
    station,
    zones,
  } = props;

  const zoneIos = [];
  for (let zone of zones) {
    for (let zoneIo of zone.ios) {
      if (zoneIo.config.inputs && zoneIo.config.inputs.indexOf('IMAGE_INPUT') > -1) {
        zoneIos.push(zoneIo);
      }
    }
  }

  const [selectedDate, setSelectedDate] = useState(moment().startOf('day'));
  const [selectedDatetime, setSelectedDatetime] = useState(moment().startOf('hour').valueOf());
  const [loadingCameraFiles, setLoadingCameraFiles] = useState(true);
  const [cameraFiles, setCameraFiles] = useState(null);
  const [cameraFilesShown, setCameraFilesShown] = useState({});

  const beginAt = moment(selectedDate).startOf('day');
  const endAt = moment(beginAt).add(1, 'day');

  useEffect(() => {
    setLoadingCameraFiles(true);
    setCameraFilesShown({});

    (async() => {
      const cameraFilesNew = {};

      for (let zoneIo of zoneIos) {
        const res = await ToolRequest.request('GET', '/v1/station-file', {
          stationId: station.id,
          zoneIoId: zoneIo.id,
          beginAt: beginAt.toISOString(),
          endAt: endAt.toISOString(),
          count: 1000,
          ascending: true,
        });

        cameraFilesNew[zoneIo.id] = res.data.map(cameraFile => ({
          ...cameraFile,
          capturedAtValue: moment(cameraFile.capturedAt).valueOf(),
        }));
      }

      setCameraFiles(cameraFilesNew);
      setLoadingCameraFiles(false);
    })();
  }, [selectedDate]);

  useEffect(() => {
    if (!loadingCameraFiles) {
      const cameraFilesShownNew = {};

      for (let zoneIo of zoneIos) {
        cameraFilesShownNew[zoneIo.id] = cameraFiles[zoneIo.id].find(cameraFile => cameraFile.capturedAtValue === selectedDatetime);

        if (!cameraFilesShownNew[zoneIo.id]) {
          for (let i = cameraFiles[zoneIo.id].length - 1; i >= 0; --i) {
            if (cameraFiles[zoneIo.id][i].capturedAtValue < selectedDatetime) {
              cameraFilesShownNew[zoneIo.id] = cameraFiles[zoneIo.id][i];
              break;
            }
          }
        }
      }

      setCameraFilesShown(cameraFilesShownNew);
    }
  }, [loadingCameraFiles, selectedDate, selectedDatetime]);

  return (
    <>
      <Row gutter={[8, 8]}>
        {zoneIos.map(zoneIo => {
          return (
            <Col key={zoneIo.id} xs={24} lg={12}>
              <Card
                size="small"
                className="card-container"
                style={{
                  textAlign: 'center',
                }}
              >
                {loadingCameraFiles ? (
                  <SpinFull
                    style={{
                      height: 200,
                    }}
                  />
                ) : (cameraFilesShown[zoneIo.id] ? (
                  <Image
                    src={cameraFilesShown[zoneIo.id].url}
                    height={200}
                  />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No image"
                    style={{
                      height: 200,
                      margin: 0,
                      paddingTop: 32,
                    }}
                  />
                ))}
                <div style={{
                  marginTop: 4,
                }}>
                  {zoneIo.name}
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>

      <Space.Compact
        block
        style={{
          width: '100%',
          marginTop: 8,
        }}
      >
        <Button
          onClick={() => {
            setSelectedDate(moment(selectedDate).subtract(1, 'day'));
            setSelectedDatetime(selectedDatetime - 86400000);
          }}
        >
          <DoubleLeftOutlined />
          {moment(selectedDate).subtract(1, 'day').format('YYYY-MM-DD')}
        </Button>
        <DatePicker
          style={{
            flex: 1,
          }}
          allowClear={false}
          onChange={date => setSelectedDate(date)}
          value={selectedDate}
        />
        <Button
          onClick={() => {
            setSelectedDate(moment(selectedDate).add(1, 'day'));
            setSelectedDatetime(selectedDatetime + 86400000);
          }}
        >
          {moment(selectedDate).add(1, 'day').format('YYYY-MM-DD')}
          <DoubleRightOutlined />
        </Button>
      </Space.Compact>

      <div style={{
      }}>
        <div style={{
          position: 'relative',
        }}>
          <Slider
            value={selectedDatetime}
            onChange={value => setSelectedDatetime(value)}
            min={beginAt.valueOf()}
            max={endAt.valueOf()}
            step={3600000}
            dots
            tooltip={{
              formatter: (value) => moment(value).format('HH:mm'),
            }}
            railStyle={{
              height: 24 * zoneIos.length + 10,
            }}
            trackStyle={{
              height: 24 * zoneIos.length + 10,
            }}
            handleStyle={{
              height: 24 * zoneIos.length + 20,
              borderRadius: 4,
              zIndex: 1,
            }}
            style={{
              height: 24 * zoneIos.length + 20,
            }}
          />

          <div style={{
            position: 'absolute',
            left: 6,
            right: 6,
            top: 10,
            bottom: 0,
            zIndex: 0,
            pointerEvents: 'none',
            userSelect: 'none',
            overflow: 'hidden',
          }}>
            {loadingCameraFiles ? (
              <SpinFull
                style={{
                  height: 24 * zoneIos.length + 10,
                }}
              />
            ) : zoneIos.map(zoneIo => {
              for (let i = cameraFiles[zoneIo.id].length - 2; i >= 0; --i) {
                cameraFiles[zoneIo.id][i].duration = cameraFiles[zoneIo.id][i + 1].capturedAtValue - cameraFiles[zoneIo.id][i].capturedAtValue;
              }
              if (cameraFiles[zoneIo.id].length) {
                cameraFiles[zoneIo.id][cameraFiles[zoneIo.id].length - 1].duration = endAt.valueOf() - cameraFiles[zoneIo.id][cameraFiles[zoneIo.id].length - 1].capturedAtValue;
              }

              return (
                <div style={{
                  position: 'relative',
                  height: 24,
                }}>
                  {cameraFiles[zoneIo.id].map(cameraFile => {
                    return (
                      <div style={{
                        position: 'absolute',
                        width: `${cameraFile.duration / 864000}%`,
                        height: '100%',
                        left: `${(cameraFile.capturedAtValue - beginAt.valueOf()) / 864000}%`,
                        backgroundImage: `url(${cameraFile.url})`,
                        backgroundSize: 'auto 100%',
                        backgroundRepeat: 'no-repeat',
                        opacity: cameraFilesShown[zoneIo.id] === cameraFile ? 1 : .2,
                      }} />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};