import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Card, Row, Col, Statistic, Badge, Tabs, Button, Tooltip, Spin, Switch, Divider } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../../tools/ToolRequest';
import * as ToolMath from '../../../tools/ToolMath';

import StationDashboardOverviewCamera from './StationDashboardOverviewCamera';
import StationDashboardOverviewEvent from './StationDashboardOverviewEvent';
import StationDashboardOverviewInputCard from './StationDashboardOverviewInputCard';
import StationDashboardOverviewOutputCard from './StationDashboardOverviewOutputCard';
import StationDashboardInputCard from '../StationDashboardInput/StationDashboardInputCard';
import StationDashboardOutputEditModal from '../StationDashboardOutput/StationDashboardOutputEditModal';
import StationDashboardAutomationEditModal from '../StationDashboardAutomation/StationDashboardAutomationEditModal';
import SpinFull from '../../../components/SpinFull';

export default function StationDashboardGraphTab(props) {
  const {
    station,
    zones,
    zoneStatuses,
    reloadingStationZoneStatuses,
    reloadingStationZoneStatusesCountdown,
    reloadStationZoneStatuses,
  } = props;

  const [stationStatus, setStationStatus] = useState(null);
  const [reloadingStationStatuses, setReloadingStationStatuses] = useState(false);

  const [editOutputModal, setEditOutputModal] = useState(null);
  const [editAutomationModal, setEditAutomationModal] = useState(null);

  const loadStationStatus = () => {
    (async () => {
      setReloadingStationStatuses(true);

      // station status
      const stationStatus = await ToolRequest.request('GET', '/v1/station-status', {
        stationId: station.id,
      });
      setStationStatus(stationStatus);

      setReloadingStationStatuses(false);
    })();
  };

  useEffect(() => {
    loadStationStatus();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      loadStationStatus();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col xs={24} md={8}>
          <Card className="card-container" style={{height: 274}}>
            {stationStatus ? (
              <React.Fragment>
                <Row>
                  <Col xs={24} sm={12}>
                    <Statistic
                      title="Running Days"
                      value={station && station.createdAt}
                      formatter={value => value ? ToolMath.daysFromNow(value, true) : '-'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={12}>
                    <Statistic
                      title="Connection"
                      prefix={<Badge status={stationStatus.isSocketConnecting ? 'success' : 'error'} />}
                      value={`${stationStatus ? (stationStatus.isSocketConnecting ? 'Online' : 'Offline') : '-'}${station.deviceNo ? ` (#${station.deviceNo})` : ''}`}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <Statistic
                      title="Last Connection"
                      value={stationStatus && (stationStatus.isSocketConnecting ? stationStatus.socketCurrentConnectionBeginAt : stationStatus.cloudLastConnectionEndAt)}
                      formatter={value => value ? (
                        <Tooltip title={moment(value).format('YYYY-MM-DD HH:mm:ss')}>{moment(value).fromNow()}</Tooltip>
                      ) : '-'}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ) : <SpinFull />}
          </Card>
        </Col>

        <Col xs={24} md={8}>
          <StationDashboardOverviewEvent
            station={station}
          />
        </Col>

        <Col xs={24} md={8}>
          <StationDashboardOverviewCamera
            station={station}
            zones={zones}
          />
        </Col>
      </Row>

      {stationStatus && zoneStatuses ? (
        <Spin spinning={reloadingStationZoneStatuses}>
          {zones.map(zone => (
            <Card
              key={zone.id}
              className="card-container"
              style={{marginTop: 16}}
              title={
                <>
                  <Button
                    type="link"
                    size="small"
                    icon={<ReloadOutlined />}
                    style={{float: 'right'}}
                    onClick={() => reloadStationZoneStatuses()}
                    disabled={reloadingStationZoneStatuses}
                  >
                    <span>{reloadingStationZoneStatusesCountdown}</span>
                  </Button>

                  <span style={{marginRight: 8}}>{zone.name}</span>
                  {zoneStatuses[zone.id] && zoneStatuses[zone.id][`latestUpdatedAt`] && (
                    <Tooltip title={moment(zoneStatuses[zone.id][`latestUpdatedAt`]).format('YYYY-MM-DD HH:mm:ss')}>
                      ({moment(zoneStatuses[zone.id][`latestUpdatedAt`]).fromNow()})
                    </Tooltip>
                  )}
                </>
              }
            >
              <Divider>Sensors</Divider>

              <Row gutter={8}>
                {zone.ios.filter(zoneIo => zoneIo.config.inputs).map(zoneIo => {
                  const zoneIoConfig = zoneIo.config;

                  let latestValue = zoneStatuses[zone.id][`latestInputValue[${zoneIo.id}]`];
                  if (latestValue === undefined) {
                    latestValue = null;
                  }

                  let latestValueUpdatedAt = zoneStatuses[zone.id][`latestInputValueUpdatedAt[${zoneIo.id}]`];

                  if (latestValueUpdatedAt && moment().diff(latestValueUpdatedAt, 'minute') > 30) {
                    latestValue = null;
                  }

                  let className = '';
                  let suffix;
                  let unit;
                  let min = zoneIo.settings.min;
                  let max = zoneIo.settings.max;
                  let formatter = v => v;
                  let targetValue = zoneIo.settings.targetValue;
                  let safePercent = zoneIo.settings.targetValueSafePercent;
                  let even = false;
                  let statusFormatter = null;

                  switch (zoneIo.type) {
                    case 'AIR_HUMIDITY': {
                      className = 'home-sensor-card-air-humidity';
                      suffix = '%';
                      formatter = value => value === null ? '-' : value.toFixed(1);
                      break;
                    }
                    case 'AIR_TEMPERATURE': {
                      className = 'home-sensor-card-air-temperature';
                      suffix = '°C';
                      formatter = value => value === null ? '-' : value.toFixed(1);
                      break;
                    }
                    case 'CO2_PPM': {
                      className = 'home-sensor-card-co2-ppm';
                      suffix = 'ppm';
                      formatter = value => value === null ? '-' : value.toFixed(0);
                      break;
                    }
                    case 'EC_VALUE': {
                      className = 'home-sensor-card-ec-value';
                      unit = 'us/cm';
                      formatter = value => value === null ? '-' : value.toFixed(0);
                      break;
                    }
                    case 'PH_VALUE': {
                      className = 'home-sensor-card-ph-value';
                      formatter = value => value === null ? '-' : value.toFixed(1);
                      break;
                    }
                    case 'WATER_LEVEL':
                    case 'WATER_LEVEL_DIGITAL': {
                      className = 'home-sensor-card-water-level';
                      formatter = value => ['Low', 'Normal', 'Full'][value] || '-';
                      statusFormatter = value => {
                        if (value === 0) {
                          return 'danger';
                        } else {
                          return 'safe';
                        }
                      };
                      break;
                    }
                    case 'WATER_TEMPERATURE': {
                      className = 'home-sensor-card-water-temperature';
                      formatter = value => value === null ? '-' : value.toFixed(1);
                      break;
                    }
                    case 'DISSOLVED_OXYGEN': {
                      className = 'home-sensor-card-dissolved-oxygen';
                      unit = 'mg/L';
                      formatter = value => value === null ? '-' : value.toFixed(2);
                      break;
                    }
                    case 'OXYGEN_SATURATION': {
                      className = 'home-sensor-card-oxygen-saturation';
                      suffix = '%';
                      formatter = value => value === null ? '-' : value.toFixed(1);
                      break;
                    }
                    case 'OXYGEN_PARTIAL_PRESSURE': {
                      className = 'home-sensor-card-oxygen-partial-pressure';
                      unit = 'hPa';
                      formatter = value => value === null ? '-' : value.toFixed(0);
                      break;
                    }
                    case 'CAMERA': {
                      return;
                    }
                  }

                  return (
                    <Col key={zoneIo.id} xs={24} lg={12} xl={6}>
                      <StationDashboardOverviewInputCard
                        className={className}
                        title={`${zoneIo.name}${unit ? ` (${unit})` : ''}`}
                        value={latestValue}
                        formatter={formatter}
                        suffix={suffix}
                        min={min}
                        max={max}
                        targetValue={targetValue}
                        safePercent={safePercent}
                        even={even}
                        statusFormatter={statusFormatter}
                      />
                    </Col>
                  );
                })}
              </Row>

              <Divider>Controls</Divider>
              <Row gutter={8} style={{ marginTop: 16 }}>
                {zone.ios.filter(zoneIo => zoneIo.config.outputs).map(zoneIo => {
                  const zoneIoConfig = zoneIo.config;

                  let latestValue = zoneStatuses[zone.id][`latestOutputValue[${zoneIo.id}]`];
                  if (latestValue === undefined) {
                    latestValue = null;
                  }

                  let latestValueUpdatedAt = zoneStatuses[zone.id][`latestOutputValueUpdatedAt[${zoneIo.id}]`];

                  let className = '';

                  switch (zoneIo.type) {
                    case 'LED': {
                      className = 'home-control-card-led';
                      break;
                    }
                    case 'MAIN_WATER_PUMP': {
                      className = 'home-control-card-main-water-pump';
                      break;
                    }
                    case 'NUTRITION_PUMP': {
                      className = 'home-control-card-nutrition-pump';
                      break;
                    }
                    case 'INLET_VALVE': {
                      className = 'home-control-card-inlet-valve';
                      break;
                    }
                    case 'FAN': {
                      className = 'home-control-card-fan';
                      break;
                    }
                    case 'CO2_VALVE': {
                      className = 'home-control-card-co2-valve';
                      break;
                    }
                  }

                  return (
                    <Col key={zoneIo.id} xs={24} lg={12} xl={6}>
                      <StationDashboardOverviewOutputCard
                        title={zoneIo.name}
                        value={latestValue}
                        className={className}
                        onClick={() => {
                          setEditOutputModal({
                            key: Math.random().toString(),
                            zoneIoId: zoneIo.id,
                            visible: true,
                          });
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>

              <Divider>Automations</Divider>
              <Row gutter={8} style={{ marginTop: 16 }}>
                {zone.automations.map(zoneAutomation => {

                  let className = '';

                  switch (zoneAutomation.type) {
                    case 'MAIN_WATER_PUMP_BASIC': {
                      className = 'home-automation-card-main-water-pump-basic';
                      break;
                    }
                    case 'NUTRITION_PUMP_BASIC': {
                      className = 'home-automation-card-nutrition-pump-basic';
                      break;
                    }
                    case 'LED_BASIC': {
                      className = 'home-automation-card-led-basic';
                      break;
                    }
                    case 'CAMERA_BASIC': {
                      className = 'home-automation-card-camera-basic';
                      break;
                    }
                    case 'FAN_BASIC': {
                      className = 'home-automation-card-fan-basic';
                      break;
                    }
                    case 'INLET_WATER_BASIC': {
                      className = 'home-automation-card-inlet-valve-basic';
                      break;
                    }
                    case 'CO2_BASIC': {
                      className = 'home-automation-card-co2-basic';
                      break;
                    }
                    case 'PLC_DIRECT': {
                      className = 'home-automation-card-plc-direct';
                      break;
                    }
                  }

                  return (
                    <Col key={zoneAutomation.id} xs={24} lg={12} xl={6}>
                      <StationDashboardOverviewOutputCard
                        title={zoneAutomation.name}
                        value={zoneAutomation.isEnabled}
                        className={className}
                        onClick={() => {
                          setEditAutomationModal({
                            key: Math.random().toString(),
                            zoneAutomationId: zoneAutomation.id,
                            visible: true,
                          });
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Card>
          ))}
        </Spin>
      ) : <SpinFull style={{ height: 200 }} />}

      {editOutputModal && (
        <StationDashboardOutputEditModal
          key={editOutputModal.key}
          zoneIoId={editOutputModal.zoneIoId}
          visible={editOutputModal.visible}
          onOk={() => {
            setEditOutputModal({
              ...editOutputModal,
              visible: false,
            });

            reloadStationZoneStatuses();
          }}
          onCancel={() => {
            setEditOutputModal({
              ...editOutputModal,
              visible: false,
            });
          }}
        />
      )}
      {editAutomationModal && (
        <StationDashboardAutomationEditModal
          key={editAutomationModal.key}
          zoneAutomationId={editAutomationModal.zoneAutomationId}
          visible={editAutomationModal.visible}
          onOk={() => {
            setEditAutomationModal({
              ...editAutomationModal,
              visible: false,
            });

            reloadStationZoneStatuses();
          }}
          onCancel={() => {
            setEditAutomationModal({
              ...editAutomationModal,
              visible: false,
            });
          }}
        />
      )}
    </>
  );
};