import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { PageHeader, Dropdown, Menu, Button, Spin, Badge, Modal } from 'antd';
import { EditOutlined, DashboardOutlined, DownOutlined, PlusOutlined, SettingOutlined, EllipsisOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';
import SuperTable from '../../components/SuperTable';

export default function FarmSeedPurchaseViewScreen(props) {
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [tableKey, setTableKey] = useState(Math.random().toString());
  const [searchSiteOptions, setSearchSiteOptions] = useState([]);

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        return (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'empty': {
                    Modal.confirm({
                      title: 'Confirm to empty seed purchase tray',
                      content: 'Are you sure to empty this seed purchase?',
                      onOk: () => {
                        (async () => {
                          await ToolRequest.request('PUT', `/v1/farm-seed-purchase/${record.id}`, {
                            status: 'USED_UP',
                          });

                          setTableKey(Math.random().toString());
                        })();
                      },
                    });
                    break;
                  }
                  case 'edit': {
                    history.push(`/farm-seed-purchase/edit/${record.id}`);
                    break;
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: 'Confirm to REMOVE purchase',
                      content: 'Are you sure to remove this purchase?',
                      onOk: () => {
                        (async () => {
                          await ToolRequest.request('DELETE', `/v1/farm-seed-purchase/${record.id}`);

                          setTableKey(Math.random().toString());
                        })();
                      },
                    });
                    break;
                  }
                }
              }}>
                <Menu.Item key="empty" disabled={record.status !== 'NORMAL'}>
                  <DeleteOutlined />
                  Empty
                </Menu.Item>
                <Menu.Item key="edit">
                  <EditOutlined />
                  Edit
                </Menu.Item>
                <Menu.Item key="remove">
                  <CloseOutlined />
                  Remove
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="default"
              shape="round"
            >
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
    },
    {
      template: 'seedPurchaseReadableId',
      title: 'ID',
      width: 250,
    },
    {
      key: 'name',
      title: 'Name',
    },
    {
      key: 'seedlingDayCount',
      title: 'Days of Seedling',
      width: 130,
    },
    {
      key: 'plantingDayCount',
      title: 'Days of Planting',
      width: 130,
    },
    {
      template: 'date',
      key: 'purchasedAt',
      title: 'Purchase Date',
      width: 150,
    },
    {
      key: 'siteName',
      title: 'Site',
    },
    {
      key: 'remarks',
      title: 'Remarks',
    },
  ];

  const searchColumns = [
    {
      template: 'cascader',
      key: 'siteId',
      title: 'Any Site',
      options: searchSiteOptions,
    },
    {
      template: 'cascader',
      key: 'status',
      title: 'Any Status',
      options: [
        {
          label: (
            <Badge
              status="success"
              text="Available"
            />
          ),
          value: 'NORMAL',
        },
        {
          label: (
            <Badge
              status="warning"
              text="Used Up"
            />
          ),
          value: 'USED_UP',
        },
        {
          label: (
            <Badge
              status="error"
              text="Staled"
            />
          ),
          value: 'STALED',
        },
      ],
    },
  ];

  const searchQueryParams = values => {
    return {
      searchText: values.search ? `%${values.search}%` : undefined,
      siteId: values.siteId ? values.siteId[0] : undefined,
      status: values.status ? values.status[0] : undefined,
    };
  };

  useEffect(() => {
    (async () => {
      // site
      const siteRes = await ToolRequest.request('GET', '/v1/farm-site', {
        count: 1000,
      });

      setSearchSiteOptions(siteRes.data.map(site => ({
        value: site.id,
        label: site.name,
      })));

      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    const seedPurchaseRes = await ToolRequest.request('GET', '/v1/farm-seed-purchase', {
      ...body,
    });

    const siteIds = seedPurchaseRes.data.filter(seedPurcahse => seedPurcahse).map(seedPurcahse => seedPurcahse.siteId).filter((value, index, self) => self.indexOf(value) === index);
    const sites = await Promise.all(siteIds.map(siteId => ToolRequest.request('GET', `/v1/farm-site/${siteId}`).catch(err => {})));

    return {
      data: seedPurchaseRes.data.map(seedPurcahse => {
        const site = sites.find(site => site && site.id === seedPurcahse.siteId);
        return {
          ...seedPurcahse,
          siteName: site?.name || '-',
        };
      }),
      totalCount: seedPurchaseRes.totalCount,
    };
  };

  return loaded ? (
    <PageHeader
      title="Seed Purchase List"
      extra={[
        <Button
          key="1"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push('/farm-seed-purchase/add')}
        >
          Add Seed Purchase
        </Button>
      ]}
    >
      <SuperTable
        showSearchInput={true}
        searchColumns={searchColumns}
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
    </PageHeader>
  ) : <SpinFull />;
};
