import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PageHeader, Dropdown, Menu, Button, Spin, Badge } from 'antd';
import { EditOutlined, DashboardOutlined, DownOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';
import SuperTable from '../../components/SuperTable';

export default function StationViewScreen(props) {
  const history = useHistory();
  const userData = useSelector(state => state.user);
  const userPermissionsData = useSelector(state => state.userPermissions);

  const isAllowedCrossCompany = ToolPermission.hasPermission(userPermissionsData, 'ui:company', 'get:xc');

  const [loaded, setLoaded] = useState(false);
  const [tableKey, setTableKey] = useState(Math.random().toString());
  const [searchCompanyOptions, setSearchCompanyOptions] = useState([]);

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        return (
          <Dropdown.Button
            type="default"
            href={`#/station/dashboard/${record.id}`}
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    history.push(`/station/edit/${record.id}`);
                    break;
                  }
                  case 'setting': {
                    history.push(`/station/setting/${record.id}`);
                    break;
                  }
                }
              }
            }>
              <Menu.Item key="edit">
                <EditOutlined />
                Edit
              </Menu.Item>
              <Menu.Item key="setting">
                <SettingOutlined />
                Settings
              </Menu.Item>
            </Menu>
          }>
            <DashboardOutlined />
            <span className="collapsible">Dashboard</span>
          </Dropdown.Button>
        );
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'displayName',
      title: 'Name',
    },
    {
      key: 'companyName',
      width: 220,
      title: 'Company',
    },
    {
      template: 'stationStatus',
      key: 'status',
      width: 120,
      title: 'Status',
    },
    {
      key: 'deviceNo',
      width: 120,
      title: 'Device No',
    },
    {
      template: 'isEnabled',
      key: 'isEnabled',
      width: 120,
      title: 'Active',
    },
  ];

  const searchColumns = [
    ...(isAllowedCrossCompany ? [{
      template: 'cascader',
      key: 'companyId',
      title: 'Company',
      options: searchCompanyOptions,
    }] : []),
    {
      template: 'cascader',
      key: 'isEnabled',
      title: 'Any Active',
      defaultValue: true,
      options: [
        {
          value: true,
          label: 'Active',
        },
        {
          value: false,
          label: 'Inactive',
        },
      ],
    },
  ];

  const searchQueryParams = values => {
    return {
      companyId: values.companyId ? values.companyId[0] : undefined,
      isEnabled: values.isEnabled,
    };
  };

  useEffect(() => {
    (async () => {
      if (isAllowedCrossCompany) {
        const companyRes = await ToolRequest.request('GET', '/v1/company', {
          count: 1000,
        });

        setSearchCompanyOptions(companyRes.data.map(company => ({
          value: company.id,
          label: company.displayName,
        })));
      }

      setLoaded(true);
    })();
  }, []);

  const loadDataSource = async (body) => {
    const stationRes = await ToolRequest.request('GET', '/v1/station', {
      ...body,
    });

    const companyIds = stationRes.data.filter(station => station).map(station => station.companyId).filter((value, index, self) => self.indexOf(value) === index);

    const companies = await Promise.all(companyIds.map(companyId => ToolRequest.request('GET', `/v1/company/${companyId}`).catch(err => {})));

    return {
      data: stationRes.data.map(station => {
        const company = companies.find(company => company && company.id === station.companyId);
        return {
          ...station,
          companyName: company?.displayName || '-',
        };
      }),
      totalCount: stationRes.totalCount,
    };
  };

  return loaded ? (
    <PageHeader
      title="Station List"
      extra={[
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push('/station/add')}
        >
          Add Station
        </Button>
      ]}
    >
      <SuperTable
        searchColumns={searchColumns}
        searchQueryParams={searchQueryParams}
        tableKey={tableKey}
        onTableKeyChange={key => setTableKey(key)}
        loadDataSource={loadDataSource}
        columns={columns}
      />
    </PageHeader>
  ) : <SpinFull />;
};
