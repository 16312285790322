import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Layout } from 'antd';

import HomeScreen from '../Home/HomeScreen';
import StationViewScreen from '../Station/StationViewScreen';
import StationAddScreen from '../Station/StationAddScreen';
import StationEditScreen from '../Station/StationEditScreen';
import StationSettingScreen from '../StationSetting/StationSettingScreen';
import StationDashboardScreen from '../StationDashboard/StationDashboardScreen';
import FarmSiteViewScreen from '../FarmSite/FarmSiteViewScreen';
import FarmSiteAddScreen from '../FarmSite/FarmSiteAddScreen';
import FarmSiteEditScreen from '../FarmSite/FarmSiteEditScreen';
import FarmSiteStationEditScreen from '../FarmSite/FarmSiteStationEditScreen';
import FarmSiteDashboardScreen from '../FarmSiteDashboard/FarmSiteDashboardScreen';
import FarmSeedPurchaseViewScreen from '../FarmSeedPurchase/FarmSeedPurchaseViewScreen';
import FarmSeedPurchaseAddScreen from '../FarmSeedPurchase/FarmSeedPurchaseAddScreen';
import FarmSeedPurchaseEditScreen from '../FarmSeedPurchase/FarmSeedPurchaseEditScreen';
import FarmSeedlingTrayViewScreen from '../FarmSeedlingTray/FarmSeedlingTrayViewScreen';
import FarmSeedlingTrayAddScreen from '../FarmSeedlingTray/FarmSeedlingTrayAddScreen';
import FarmSeedlingTrayEditScreen from '../FarmSeedlingTray/FarmSeedlingTrayEditScreen';
import FarmPlantingTrayViewScreen from '../FarmPlantingTray/FarmPlantingTrayViewScreen';
import FarmPlantingTrayAddScreen from '../FarmPlantingTray/FarmPlantingTrayAddScreen';
import FarmPlantingTrayEditScreen from '../FarmPlantingTray/FarmPlantingTrayEditScreen';
import FarmSeedlingRecordViewScreen from '../FarmSeedlingRecord/FarmSeedlingRecordViewScreen';
import FarmSeedlingRecordAddScreen from '../FarmSeedlingRecord/FarmSeedlingRecordAddScreen';
import FarmSeedlingRecordEditScreen from '../FarmSeedlingRecord/FarmSeedlingRecordEditScreen';
import FarmPlantingRecordViewScreen from '../FarmPlantingRecord/FarmPlantingRecordViewScreen';
import FarmPlantingRecordAddScreen from '../FarmPlantingRecord/FarmPlantingRecordAddScreen';
import FarmPlantingRecordEditScreen from '../FarmPlantingRecord/FarmPlantingRecordEditScreen';
import FarmHarvestRecordViewScreen from '../FarmHarvestRecord/FarmHarvestRecordViewScreen';
import FarmHarvestRecordAddScreen from '../FarmHarvestRecord/FarmHarvestRecordAddScreen';
import FarmHarvestRecordEditScreen from '../FarmHarvestRecord/FarmHarvestRecordEditScreen';

const { Content } = Layout;

export default function AppContent(props) {
  return (
    <Content id="mainLayoutContent">
      <div>
        <Switch>
          <Route exact path="/station" component={StationViewScreen} />
          <Route exact path="/station/add" component={StationAddScreen} />
          <Route exact path="/station/edit/:stationId" component={StationEditScreen} />
          <Route exact path="/station/setting/:stationId" component={StationSettingScreen} />
          <Route exact path="/station/dashboard/:stationId" component={StationDashboardScreen} />
          <Route exact path="/farm-site" component={FarmSiteViewScreen} />
          <Route exact path="/farm-site/add" component={FarmSiteAddScreen} />
          <Route exact path="/farm-site/edit/:siteId" component={FarmSiteEditScreen} />
          <Route exact path="/farm-site/edit/:siteId/station/edit" component={FarmSiteStationEditScreen} />
          <Route exact path="/farm-site/dashboard/:siteId" component={FarmSiteDashboardScreen} />
          <Route exact path="/farm-seed-purchase" component={FarmSeedPurchaseViewScreen} />
          <Route exact path="/farm-seed-purchase/add" component={FarmSeedPurchaseAddScreen} />
          <Route exact path="/farm-seed-purchase/edit/:seedPurchaseId" component={FarmSeedPurchaseEditScreen} />
          <Route exact path="/farm-seedling-tray" component={FarmSeedlingTrayViewScreen} />
          <Route exact path="/farm-seedling-tray/add" component={FarmSeedlingTrayAddScreen} />
          <Route exact path="/farm-seedling-tray/edit/:seedlingTrayId" component={FarmSeedlingTrayEditScreen} />
          <Route exact path="/farm-planting-tray" component={FarmPlantingTrayViewScreen} />
          <Route exact path="/farm-planting-tray/add" component={FarmPlantingTrayAddScreen} />
          <Route exact path="/farm-planting-tray/edit/:plantingTrayId" component={FarmPlantingTrayEditScreen} />
          <Route exact path="/farm-seedling-record" component={FarmSeedlingRecordViewScreen} />
          <Route exact path="/farm-seedling-record/add" component={FarmSeedlingRecordAddScreen} />
          <Route exact path="/farm-seedling-record/edit/:seedlingRecordId" component={FarmSeedlingRecordEditScreen} />
          <Route exact path="/farm-planting-record" component={FarmPlantingRecordViewScreen} />
          <Route exact path="/farm-planting-record/add" component={FarmPlantingRecordAddScreen} />
          <Route exact path="/farm-planting-record/edit/:plantingRecordId" component={FarmPlantingRecordEditScreen} />
          <Route exact path="/farm-harvest-record" component={FarmHarvestRecordViewScreen} />
          <Route exact path="/farm-harvest-record/add" component={FarmHarvestRecordAddScreen} />
          <Route exact path="/farm-harvest-record/edit/:harvestRecordId" component={FarmHarvestRecordEditScreen} />
          <Route component={HomeScreen} />
        </Switch>
      </div>
    </Content>
  );
};
