import React, { useState, useEffect } from 'react';

import ReactECharts from 'echarts-for-react';

export default function StationDashboardInputCard(props) {
  const {
    title,
    suffix='',
    value,
    min,
    max,
    targetValue=(max + min) / 2,
    safePercent=0.1,
    formatter=(value) => value,
    even=false,
    axisSplitNumber=10,
  } = props;

  const range = max - min;
  const targetPercent = targetValue / range;

  return (
    <ReactECharts
      {...props}
      option={{
        series: [
          {
            type: 'gauge',
            startAngle: 210,
            endAngle: -30,
            min,
            max,
            splitNumber: axisSplitNumber,
            center: ['50%', '52%'],
            radius: '95%',
            itemStyle: {
              color: 'rgba(0, 0, 0, .5)'
            },
            pointer: {
              show: true,
              // itemStyle: {
              //   color: 'auto',
              // },
            },
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [targetPercent - safePercent * (even ? 1.5 : 1), '#ff4d4f'],
                  [targetPercent - safePercent / 2, '#ffec3d'],
                  [targetPercent + safePercent / 2, '#52c41a'],
                  [targetPercent + safePercent * (even ? 1.5 : 1), '#ffec3d'],
                  [1, '#ff4d4f'],
                ]
              }
            },
            axisTick: {
              // show: false,
              distance: -10,
              splitNumber: 5,
              length: 2,
              lineStyle: {
                width: 1,
                color: '#fff'
              }
            },
            splitLine: {
              // show: false,
              distance: -10,
              length: 10,
              lineStyle: {
                width: 1,
                color: '#fff'
              }
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false
            },
            title: {
              show: true,
              width: '60%',
              lineHeight: 40,
              height: '15%',
              offsetCenter: [0, 70],
              color: '#999',
              fontSize: 12,
            },
            detail: {
              valueAnimation: true,
              width: '60%',
              lineHeight: 40,
              height: '15%',
              offsetCenter: [0, 50],
              fontSize: 18,
              fontWeight: 'bold',
              formatter: value => {
                return isNaN(value) ? '-' : `${formatter(value)}${suffix}`;
              },
            },
            data: [{
              value,
              name: title,
            }],
          },
        ],
      }}
    />
  );
};
