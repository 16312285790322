export const UPDATE_METADATA = 'UPDATE_METADATA';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS';
export const UPDATE_COMPANY_SETTINGS = 'UPDATE_COMPANY_SETTINGS';
export const UPDATE_ZONE_IO_CONFIGS = 'UPDATE_ZONE_IO_CONFIGS';
export const UPDATE_STATION_SETTING_CONFIGS = 'UPDATE_STATION_SETTING_CONFIGS';
export const UPDATE_STATION_DEVICE_CONFIGS = 'UPDATE_STATION_DEVICE_CONFIGS';
export const UPDATE_ZONE_AUTOMATION_CONFIGS = 'UPDATE_ZONE_AUTOMATION_CONFIGS';

export function updateMetadata(metadata) {
	return {
		type: UPDATE_METADATA,
		metadata,
	};
}

export function updateUser(user) {
	return {
		type: UPDATE_USER,
		user,
	};
}

export function updateUserPermissions(userPermissions) {
	return {
		type: UPDATE_USER_PERMISSIONS,
		userPermissions,
	};
}

export function updateCompanySettings(companySettings) {
	return {
		type: UPDATE_COMPANY_SETTINGS,
		companySettings,
	};
}

export function updateZoneIoConfigs(zoneIoConfigs) {
	return {
		type: UPDATE_ZONE_IO_CONFIGS,
		zoneIoConfigs,
	};
}

export function updateStationSettingConfigs(stationSettingConfigs) {
	return {
		type: UPDATE_STATION_SETTING_CONFIGS,
		stationSettingConfigs,
	};
}

export function updateStationDeviceConfigs(stationDeviceConfigs) {
	return {
		type: UPDATE_STATION_DEVICE_CONFIGS,
		stationDeviceConfigs,
	};
}

export function updateZoneAutomationConfigs(zoneAutomationConfigs) {
	return {
		type: UPDATE_ZONE_AUTOMATION_CONFIGS,
		zoneAutomationConfigs,
	};
}
