import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageHeader, Button, Transfer, Space, Typography, Spin, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const { Title } = Typography;

export default function FarmSiteStationEditScreen(props) {
  const history = useHistory();
  const { siteId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [site, setSite] = useState(null);

  const [stations, setStations] = useState(null);
  const [selectedStationIds, setSelectedStationIds] = useState([]);
  const [targetStationIds, setTargetStationIds] = useState(null);

  const { metadata } = props;

  useEffect(() => {
    (async () => {
      const siteRes = await ToolRequest.request('GET', `/v1/farm-site/${siteId}`);
      setSite(siteRes);

      const siteStationSelectedRes = await ToolRequest.request('GET', `/v1/farm-site/${siteId}/station`, {
        siteId,
        count: 1000,
      });
      setTargetStationIds(siteStationSelectedRes.data.map(siteStation => siteStation.stationId));

      const stationUnselectedRes = await ToolRequest.request('GET', `/v1/station`, {
        siteId: 'null',
        count: 1000,
      });

      const stationRes = await ToolRequest.request('GET', `/v1/station`, {
        count: 1000,
      });
      setStations(stationRes.data.filter(station => stationUnselectedRes.data.some(station2 => station2.id === station.id)
          || siteStationSelectedRes.data.some(siteStation => siteStation.stationId === station.id)));

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      for (let station of stations) {
        try {
          const action = targetStationIds.indexOf(station.id) > -1 ? 'add' : 'remove';
          const res = await ToolRequest.request('POST', `/v1/farm-site/${siteId}/station/${action}`, {
            stationId: station.id,
          });
        } catch (err) {
          if (err?.response?.data) {
            switch (err.response.data) {
              case 'STATION_ALREADY_BINDED':
              case 'STATION_NOT_BINDED':
                break;
              default:
                throw err;
            }
          }
        }
      }

      message.success('Update Site Station Bindings successfully');

      history.push('/farm-site');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error('Unknown errors. Please retry');
        }
      }

      message.error('Unknown error. Please retry');
    }
  };

  const onChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetStationIds(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedStationIds([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  return loaded ? (
    <PageHeader
      title={`Bind Site Stations (${site.name})`}
      onBack={() => history.push('/farm-site')}
    >
      <Spin spinning={loading}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Transfer
            listStyle={{ width: '100%' }}
            titles={['Available Stations', 'Binding Stations']}
            dataSource={stations.map(station => ({
              ...station,
              key: station.id,
            }))}
            targetKeys={targetStationIds}
            selectedKeys={selectedStationIds}
            onChange={onChange}
            onSelectChange={onSelectChange}
            render={item => item.displayName}
          />
          <Button
            type="primary"
            block
            onClick={() => onFinish()}
          >
            Update
          </Button>
        </Space>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
