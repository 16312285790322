import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import difference from 'lodash/difference';

import { Modal, Form, Input, Button, Table, Select, Spin, message, Divider, Transfer, Tag, Space, Switch, Progress } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    sm: { offset: 5, span: 16 },
  },
};

const { Option } = Select;

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;
      const rowSelection = {
        getCheckboxProps: (item) => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };
      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{
            pointerEvents: listDisabled ? 'none' : undefined,
          }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

export default function StationZoneIoBatchEditModal(props) {
  const {
    stationId,
    visible,
    zones,
    onUpdate,
    onOk,
    onCancel,
  } = props;
  const zoneIoConfigsData = useSelector(state => state.zoneIoConfigs);

  const [loading, setLoading] = useState(false);
  const [zoneIoSettingsHasChanged, setZoneIoSettingsHasChanged] = useState({});
  const [zoneIoSettingsWillUpdate, setZoneIoSettingsWillUpdate] = useState({});
  const [selectedZoneIoIds, setSelectedZoneIoIds] = useState([]);

  const onFinish = async (values) => {

    const updatingKeys = Object.keys(zoneIoSettingsWillUpdate).filter(key => zoneIoSettingsWillUpdate[key]);

    for (let key of updatingKeys) {
      const io = zones.find(zone => zone.ios.some(io => io.id === selectedZoneIoIds[0])).ios.find(io => io.id === selectedZoneIoIds[0]);
      const settingConfigs = zoneIoConfigsData.find(zoneIoConfig => zoneIoConfig.type === io.type).settings;
      const settingConfig = settingConfigs.find(settingConfig => settingConfig.key === key);

      let value = values[`settings.${key}`];
      switch (settingConfig.type) {
        case 'string':
        case 'option': {
          value = value !== undefined ? value : null;
          break;
        }
        case 'number':
          value = isNaN(parseFloat(value)) ? null : parseFloat(value);
          break;
      }

      for (let ioId of selectedZoneIoIds) {
        onUpdate({
          name: 'ZONE_IO_SETTING',
          action: 'UPDATE',
          id: ioId,
          key,
          value,
        });
      }
    }

    onOk();
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (changedFields && changedFields.length) {
      setZoneIoSettingsHasChanged({
        ...zoneIoSettingsHasChanged,
        [changedFields[0].name[0]]: true,
      });
    }
  };

  const renderSettings = () => {
    const zoneIoTypes = [];

    for (let zone of zones) {
      for (let io of zone.ios) {
        if (selectedZoneIoIds.indexOf(io.id) > -1) {
          if (zoneIoTypes.indexOf(io.type) === -1) {
            zoneIoTypes.push(io.type);
          }
        }
      }
    }

    const settingConfigsAll = [];
    for (let zoneIoType of zoneIoTypes) {
      const settings = zoneIoConfigsData.find(zoneIoConfig => zoneIoConfig.type === zoneIoType).settings;
      if (settings) {
        for (let setting of settings) {
          const found = settingConfigsAll.find(settingConfig => settingConfig.key === setting.key && settingConfig.type === setting.type);
          if (!found) {
            settingConfigsAll.push(setting);
          }
        }
      }
    }

    const settingConfigs = settingConfigsAll.filter(settingConfig => {
      return !zoneIoTypes.some(zoneIoType => {
        const settings = zoneIoConfigsData.find(zoneIoConfig => zoneIoConfig.type === zoneIoType).settings;
        if (settings) {
          return !settings.some(setting => setting.key === settingConfig.key);
        } else {
          return true;
        }
      })
    });

    return [
      settingConfigs.length ? <Divider key="divider" /> : null,
      ...settingConfigs.map((item, i) => {
        const inputComponent = () => {
          switch (item.type) {
            case 'number': {
              return (
                <Form.Item
                  name={`settings.${item.key}`}
                  rules={[
                    ...(item.isRequired && zoneIoSettingsWillUpdate[item.key] ? [{
                      required: true,
                      message: `Please enter ${item.title}`,
                    }] : []),
                  ]}
                  initialValue={item.defaultValue}
                >
                  <Input
                    type="number"
                    className={zoneIoSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                    disabled={!zoneIoSettingsWillUpdate[item.key]}
                  />
                </Form.Item>
              );
            }
            case 'string': {
              return (
                <Form.Item
                  name={`settings.${item.key}`}
                  rules={[
                    ...(item.isRequired && zoneIoSettingsWillUpdate[item.key] ? [{
                      required: true,
                      message: `Please enter ${item.title}`,
                    }] : []),
                  ]}
                  initialValue={item.defaultValue}
                >
                  <Input
                    className={zoneIoSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                    disabled={!zoneIoSettingsWillUpdate[item.key]}
                  />
                </Form.Item>
              );
            }
            case 'boolean': {
              return (
                <Form.Item
                  name={`settings.${item.key}`}
                  rules={[
                    ...(item.isRequired && zoneIoSettingsWillUpdate[item.key] ? [{
                      required: true,
                      message: `Please select ${item.title}`,
                    }] : []),
                  ]}
                  valuePropName="checked"
                  initialValue={item.defaultValue}
                >
                  <Switch
                    className={zoneIoSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                    disabled={!zoneIoSettingsWillUpdate[item.key]}
                  />
                </Form.Item>
              );
            }
            case 'option': {
              return (
                <Form.Item
                  name={`settings.${item.key}`}
                  rules={[
                    ...(item.isRequired && zoneIoSettingsWillUpdate[item.key] ? [{
                      required: true,
                      message: `Please select ${item.title}`,
                    }] : []),
                  ]}
                  initialValue={item.defaultValue}
                >
                  <Select
                    className={zoneIoSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                    placeholder="Not selected"
                    disabled={!zoneIoSettingsWillUpdate[item.key]}
                  >
                    {item.options.map((option) => {
                      return (
                        <Option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            }
            default:
              return (
                <span>(${item.key})</span>
              );
          }
        };

        return (
          <Form.Item
            {...formLayout}
            key={item.key}
            label={item.title}
          >
            {inputComponent()}
            <Switch
              checkedChildren="Updated"
              unCheckedChildren="Skipped"
              checked={zoneIoSettingsWillUpdate[item.key]}
              onChange={(checked) => setZoneIoSettingsWillUpdate({
                ...zoneIoSettingsWillUpdate,
                [item.key]: checked,
              })}
            />
          </Form.Item>
        );
      }),
    ];
  };

  // construct ios
  const zoneIoData = [];
  for (let zone of zones) {
    for (let io of zone.ios) {
      zoneIoData.push({
        key: io.id,
        id: io.id,
        type: io.type,
        name: io.name,
        zoneName: zone.name,
      })
    }
  }

  const transferColumns = [
    {
      dataIndex: 'name',
      title: 'Name',
    },
    {
      dataIndex: 'type',
      title: 'Type',
      render: (value) => <Tag>{value}</Tag>,
    },
    {
      dataIndex: 'zoneName',
      title: 'Zone',
      render: (value) => <Tag>{value}</Tag>,
    },
  ];

  return (
    <Modal
      title="Batch Edit Zone IOs"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      <Form
        {...formLayout}
        // initialValues={zoneIoFlatten}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
      >
        <Form.Item
          wrapperCol={{ span: 24 }}
        >
          <TableTransfer
            dataSource={zoneIoData}
            targetKeys={selectedZoneIoIds}
            onChange={nextSelectedZoneIoIds => setSelectedZoneIoIds(nextSelectedZoneIoIds)}
            leftColumns={transferColumns}
            rightColumns={transferColumns}
            filterOption={(inputValue, item) => {
              try {
                const regex = new RegExp(inputValue, 'i');
                return item.name.match(regex) || item.type.match(regex) || item.zoneName.match(regex);
              } catch (err) {}
            }}
            showSearch
          />
        </Form.Item>

        <Spin spinning={loading}>
          {renderSettings()}

          <Form.Item {...formTailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={!selectedZoneIoIds.length || !Object.keys(zoneIoSettingsWillUpdate).some(key => zoneIoSettingsWillUpdate[key])}
            >
              Update
            </Button>
          </Form.Item>
        </Spin>
      </Form>
    </Modal>
  );
};