import React from 'react';

import { Spin } from 'antd';

export default function SpinFull(props) {
  return (
    <div className="spin-full-spacer" {...props}>
      <Spin />
    </div>
  );
};