import React from 'react';

export default function DayCountDisplay({
  value,
  target,
}) {
  const weekCount = Math.ceil(target / 14);

  const weekDots = [];
  for (let i = 0; i < weekCount; ++i) {
    const dayDots = [];
    for (let j = 0; j < 14; ++j) {
      dayDots.push(
        <div
          key={`day-${j}`}
          className={i * 14 + j >= target ? 'hidden' : (
            value > target ? 'danger' :
              (i * 14 + j < value ? 'active' : null)
          )}
        />
      );
    }

    weekDots.push(
      <div key={`week-${i}`}>
        {dayDots}
      </div>
    );
  }

  return (
    <div className="day-count-display-container">
      {weekDots}
    </div>
  );
};